import React from "react";
import ImageSlider from "../imageSlider/imageSlider";


const DemoSlider = (props) => {

    return(
        <React.Fragment>
            <div>
                <ImageSlider {...props} />
            </div>
        </React.Fragment>
    );

};

export default DemoSlider;
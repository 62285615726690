
import React from "react";

import {Input, Checkbox} from "../common/input";
import {useState, useRef, useEffect, useContext} from "react";
import {postRegister} from "../../api/user";
import { googleLogin } from "../../api/google";

import Alert from "../common/alert";
import Button from "../common/button";
import AuthContext from "../../context/AuthProvider";
import GoogleButton from "../googleButton/googleButton";


const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/
const PWD_REGEX = /^.{8,28}$/;

const Register = (props) => {

    const userRef = useRef();
    const alertRef = useRef();
    const {setAuth} = useContext(AuthContext);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertPositive, setAlertPositive] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [check, setCheck] = useState(false);

    useEffect(()=>{
        userRef.current.focus();
    },[]);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd) && pwd.length >= 8 && pwd.length <= 24)
    }, [pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            await postRegister(user, email, pwd, check);
            setAlertPositive(true);
            setAlertTitle('Registered 😊');
            setAlertMessage('You can now log in!');
            setAlertVisible(true);

        }catch(err){
            setAlertPositive(false);
            setAlertTitle('Error');
            if (!err?.response) {
                setAlertMessage('No Server Response');
            } else {
                setAlertMessage(err.response?.data?.detail);
            }
            setAlertVisible(true);
        }
    }

    const handleLoginClick = () => {
        props.setIsLoginModalOpen(true);
        props.setOpen(false);
    }

    const googleSuccess = (e) => {
        googleLogin(e.credential).then((response)=>{
            const accessToken = response?.data?.access_token;
            setAuth({accessToken});
            localStorage.setItem('accessToken', accessToken);
            props.setOpen(false);
        }).catch((err)=>{
            console.log(err);
            setAlertPositive(false);
            setAlertTitle('Error');
            if (!err?.response) {
                setAlertMessage('No Server Response');
            } else {
                setAlertMessage(err.response?.data?.detail);
            }
            setAlertVisible(true);
        })
    }

    const googleFailure = (e) => {
        console.log(e);
    }

    return(

    <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-primary rounded-md shadow-lg">
                    <div className="mt-3">
                        <div className="mt-2 text-center">
                            <div className="flex flex-cols">
                                <h2
                                    className="text-2xl font-bold text-white sm:text-3xl md:text-4xl font-bricolage"
                                >
                                    Welcome to Canvique
                                </h2>
                            </div>
                            <Alert
                                ref={alertRef}
                                positive={alertPositive}
                                title={alertTitle}
                                text={alertMessage}
                                visible={alertVisible}
                                setVisible={setAlertVisible}
                            />

                            <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-3 gap-6 text-left">
                                <div className="col-span-3">
                                    <label
                                    htmlFor="Username"
                                    className="block text-sm font-medium text-white"
                                    >
                                    User name
                                    </label>
                                    <Input
                                        ref={userRef}
                                        type="text"
                                        id="nickname"
                                        name="nickname"
                                        className={!validName && user ? "border-2 border-rose-500" : ""}
                                        required
                                        aria-describedby="uidnote"
                                        aria-invalid={validName ? "false" : "true"}
                                        onChange={(e) => {setUser(e.currentTarget.value)}}
                                        value={user}
                                        onFocus={() => setUserFocus(true)}
                                        onBlur={() => setUserFocus(false)}
                                    />
                                    {!validName && userFocus &&
                                        <span id="pwdnote" className="mt-2 text-sm text-rose-500">
                                            4 to 24 characters. Must begin with a letter. Letters, numbers, underscores, hyphens allowed.
                                        </span>
                                    }
                                </div>

                                <div className="col-span-3">
                                    <label
                                    htmlFor="Email"
                                    className="block text-sm font-medium text-white"
                                    >
                                    Email
                                    </label>

                                    <Input
                                        type="email"
                                        id="Email"
                                        name="email"
                                        className={!validEmail && email ? "border-2 border-rose-500" : ""}
                                        onChange={(e) => {setEmail(e.currentTarget.value)}}
                                        value={email}
                                        onFocus={() => setEmailFocus(true)}
                                        onBlur={() => setEmailFocus(false)}
                                    />
                                    {!validEmail && emailFocus &&
                                        <span id="pwdnote" className="mt-2 text-sm text-rose-500">
                                            Provide a valid email address
                                        </span>
                                    }
                                </div>

                                <div className="col-span-3">
                                    <label
                                    htmlFor="Password"
                                    className="block text-sm font-medium text-white"
                                    >
                                    Password
                                    </label>

                                    <Input
                                        type="password"
                                        id="Password"
                                        name="password"
                                        className={!validPwd && pwd ? "border-2 border-rose-500" : ""}
                                        onChange={(e) => {setPwd(e.currentTarget.value)}}
                                        aria-invalid={validPwd ? "false" : "true"}
                                        required
                                        value={pwd}
                                        onFocus={() => setPwdFocus(true)}
                                        onBlur={() => setPwdFocus(false)}
                                    />
                                    {!validPwd && pwdFocus &&
                                        <span className="mt-2 text-sm text-rose-500">
                                            8 to 28 characters.<br />
                                        </span>
                                    }
                                </div>

                                <div className="col-span-3">
                                    <label htmlFor="MarketingAccept" className="flex gap-4">
                                    <Checkbox
                                        type="checkbox"
                                        id="MarketingAccept"
                                        name="marketing_accept"
                                        onChange={(e) => {setCheck(e.currentTarget.value)}}
                                    />
                                        <span className="text-sm text-white">
                                            I want to receive emails about events, product updates and
                                            company announcements.
                                        </span>
                                    </label>
                                </div>

                                <div className="col-span-3">
                                    <p className="text-sm text-white">
                                        By creating an account, you agree to our&nbsp;
                                        <a href="/legal/terms-and-conditions" className="underline text-accent-standard">
                                            terms and conditions
                                        </a>
                                        &nbsp;and&nbsp;
                                        <a href="/legal/privacy-policy" className="underline text-accent-standard">
                                            privacy policy
                                        </a>
                                    </p>
                                </div>
                                <div className="flex col-span-3 justify-center">
                                    <GoogleButton
                                        handleSuccess={googleSuccess}
                                        handleFailure={googleFailure}
                                    />
                                </div>
                                <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                                    <Button
                                        disabled={!email || !pwd ? true : false}
                                    >
                                        Create an account
                                    </Button>

                                    <p className="mt-4 text-sm text-white sm:mt-0">
                                        Already have an account?&nbsp;
                                        <a className="underline text-accent-standard cursor-pointer" onClick={()=>handleLoginClick()}>
                                            Log in
                                        </a>.
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    );
}

export default Register;
import { createContext, useState, useEffect } from "react";
import { googleLogout } from '@react-oauth/google';

import {getValidationToken} from "../api/user";
import { check_admin } from "../api/admin";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdminLoading, setIsAdminLoading] = useState(true);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            getValidationToken(accessToken)
                .then((res) => {
                    if (res.status === 200 && accessToken !== auth?.accessToken) {
                        setAuth({ accessToken });
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    localStorage.removeItem('accessToken');
                    setIsLoading(false);
                })
        } else {
            setIsLoading(false);
        }

        check_admin().then((response) => {
            setIsAdmin(response.status === 200);
            setIsAdminLoading(false);
        }).catch((error) => {
            setIsAdminLoading(false);
        });

    },[auth]);

    const logout = () => {
        localStorage.removeItem('accessToken');
        googleLogout();
        setAuth(null);
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth, isLoading, logout, isAdmin, isAdminLoading}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;

import React from 'react';

const Link = ({ href, children }) => {

    return (
        <a href={href!=null ? href : "/"} className="text-white transition hover:opacity-75">
            {children}
        </a>
    )

}

export default Link;
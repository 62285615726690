
import React from "react";
import {useCallback, useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';

import { IoClose } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuArrowUpCircle } from "react-icons/lu";

import { getDetails } from "../../../../api/image";
import Button from "../../../common/button";


const ModalDetail = (props) => {

    return (
        <div className={`flex flex-col gap-4 ${props.className}`}>
            <div className="flex flex-col gap-2">
                {props.title &&
                    <h1 className="text-accent-standard font-light select-none">
                        {props.title}
                    </h1>
                }
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    );
}


const ImageModal = (props) => {

    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [mainImageLoaded, setMainImageLoaded] = useState(false);
    const [originalImageLoaded, setOriginalImageLoaded] = useState(false);

    const [id, setId] = useState(null);
    const [url, setUrl] = useState(null);
    const [positivePrompt, setPositivePrompt] = useState(null);
    const [negativePrompt, setNegativePrompt] = useState(null);
    const [style, setStyle] = useState(undefined);
    const [original_url, setOriginal_url] = useState(null);
    const [original_id, setOriginal_id] = useState(null);
    const [original_snapshot_url, setOriginal_snapshot_url] = useState(null);
    const [image_strength, setImage_strength] = useState(null);
    const [cfg_scale, setCfg_scale] = useState(null);
    const [updateStrength, setUpdateStrength] = useState(undefined);
    const [seed, setSeed] = useState(null);



    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          props.setOpen(false);
        }
      }, []);

      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);


    useEffect(()=>{

        setMainImageLoaded(false);
        setMainImageLoaded(false);
        if(props.id){
            getDetails(props.id).then((response)=>{
                const data = response.data;
                setId(data.id);
                setUrl(data.url);
                setOriginal_url(data.original_image_url);
                setOriginal_id(data.original_image_id);
                setOriginal_snapshot_url(data.original_snapshot_url);
                setPositivePrompt(data.positive_prompt);
                setNegativePrompt(data.negative_prompt);
                setStyle(data.style);
                setImage_strength(data.image_strength);
                setCfg_scale(data.cfg_scale);
                setUpdateStrength(data.update_strength);
                setSeed(data.seed);
                setLoaded(true);
            }).catch((err)=>{
                console.log(err);
            });
        }
    }, [props.url, props.id]);

    const handleReuse = () => {

        props.SideBarDispatcher({type: "style", payload: style});
        props.SideBarDispatcher({type: "positivePrompt", payload: positivePrompt});
        props.SideBarDispatcher({type: "negativePrompt", payload: negativePrompt});
        props.setOpen(false);
        if(original_id){
            fetch(original_snapshot_url).then(async response => {
                props.SideBarDispatcher({type: "updateStrength", payload: updateStrength});
                const blob = await response.blob()
                const file = new File([blob], original_url, { type: blob.type })
                props.SideBarDispatcher({type: "file", payload: file});
                props.SideBarDispatcher({type: "image", payload: original_snapshot_url});
            })
        }
    }



    const handleMakeItCanvas = () => {

        ReactGA.event({
            category: "Button",
            action: "Make it a canvas",
            label: "Make it a canvas button clicked"
        });

        ReactPixel.trackCustom('MakeItCanvas', {
            action: "Make it a canvas",
            label: "Make it a canvas button clicked"
        });

        TiktokPixel.track('MakeCanvas');

        props.setOpen(false);
        navigate("/canvas/"+props.id);
    }

    return(
        <div className={`
                fixed top-0 left-0 z-[1000]
                flex w-full h-full bg-primary/75
                sm:h-screen sm:w-screen
                sm:items-center sm:justify-center
                ${props.open ? "visible bg-primary/90" : "invisible"}
             `}
             onClick={() => props.setOpen(false)}
             >
            <div
                onClick={(e) => e.stopPropagation()}
                className={`
                flex-col rounded-lg
                border border-secondary bg-primary flex
                w-full h-full grow overflow-hidden overflow-y-auto
                sm:max-h-[calc(100vh-5rem)] sm:max-w-[calc(100vw-5rem)]
                ${props.open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                `}
            >
                <div className="w-full border-b-[1px] border-secondary flex flex-row items-center justify-between">
                    <div></div>
                    <button onClick={()=>props.setOpen(false)}
                            className="p-1 rounded-lg mr-[5px] text-gray-400 bg-primary hover:bg-Zinc-900 hover:text-gray-600"
                    >
                        <IoClose className="w-[30px] h-[30px]" />
                    </button>
                </div>
                <div className="flex max-h-screen h-full min-h-0 w-full flex-col gap-4 overflow-y-auto p-4 md:flex-row">
                    <div className="group relative flex aspect-square shrink-0 grow flex-col justify-center overflow-hidden rounded bg-black md:min-h-0 md:shrink md:basis-0">

                        {
                            props.id && (
                                <img
                                    src={props.url}
                                    alt="Couldn't load this image :("
                                    className="h-full w-full grow basis-0 object-contain md:h-[999999px] md:min-h-0 md:w-[999999px]"
                                />
                            )
                        }
                    </div>
                    <div className="flex min-h-0 shrink-0 flex-col gap-2
                                    md:max-w-[26rem] text-white text-left
                                    text-sm">
                        <div className="overflow-y-auto overflow-x-hidden">
                                {
                                    loaded ? (
                                        <>
                                            <ModalDetail
                                                title={"Positive Prompt"}
                                                border={true}
                                                className="border-b border-secondary pb-2"
                                            >
                                                {positivePrompt}
                                            </ModalDetail>
                                            <ModalDetail
                                                title={"Negative Prompt"}
                                                border={true}
                                                className="mt-1 border-b border-secondary pb-2"
                                            >
                                                {negativePrompt ? negativePrompt : "None"}
                                            </ModalDetail>
                                            <ModalDetail
                                                title={"Style"}
                                                border={true}
                                                className="mt-1 border-b border-secondary pb-2"
                                            >
                                                {style}
                                            </ModalDetail>
                                            <ModalDetail
                                                title={"Update Stength"}
                                                border={true}
                                                className="mt-1 border-b border-secondary pb-2"
                                            >
                                                {updateStrength}
                                            </ModalDetail>
                                            <ModalDetail
                                                border={true}
                                                className="mt-1 border-b border-secondary p-2"
                                            >
                                                <div className="flex flex-row justify-around">
                                                    <FaRegTrashAlt className="w-[20px] h-[20px] cursor-pointer hover:text-secondary"
                                                                    onClick={() => {return;}}
                                                    />
                                                    <LuArrowUpCircle className="w-[20px] h-[20px] cursor-pointer hover:text-secondary"
                                                                    onClick={() => handleReuse()}
                                                    />
                                                </div>
                                            </ModalDetail>

                                            {
                                                original_id && (
                                                    <ModalDetail
                                                        title={"Original Image"}
                                                        border={false}
                                                        className="mt-2 pb-2"
                                                    >
                                                        <div className="flex w-full justify-center items-center">
                                                            <div className="group max-h-[200px] relative flex aspect-square shrink-0 grow flex-col justify-center overflow-hidden rounded bg-black md:min-h-0 md:shrink md:basis-0">
                                                                <img src={original_snapshot_url}
                                                                        className={`h-full w-full grow basis-0 object-contain md:h-[999999px] md:min-h-0 md:w-[999999px] ${originalImageLoaded ? "opacity-100" : "opacity-0"}`}
                                                                        onLoad={() => setOriginalImageLoaded(true)}
                                                                        />
                                                            </div>
                                                        </div>
                                                    </ModalDetail>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }
                        </div>
                        <div className="h-[40px] w-full flex flex-row justify-center gap-4">
                            <Button className="w-full"
                                    onClick={() => handleMakeItCanvas()}
                            >
                                Make it a canvas
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageModal;

import React, { useState } from "react";
import Button from "../common/button";
import { postForgotPassword } from "../../api/user";
import { FaCheckDouble } from "react-icons/fa";

import { BsEmojiFrown } from "react-icons/bs";


const PasswordForgotten = (props) => {

        const [email, setEmail] = useState("");
        const [done, setDone] = useState(false);

        const handleSubmit = async (e) => {
            e.preventDefault();
            postForgotPassword(email)
            .then((res) => {
                setDone(true);
            })
            .catch((err) => {
                setDone(true);
            });
        }

        return(
            <>
                {
                    !done ? (
                        <div className="flex justify-center items-center h-[80vh] w-full bg-white">
                            <div className="flex flex-col gap-2 max-w-[360px] p-4">

                                <BsEmojiFrown className="text-6xl text-primary mx-auto"/>

                                <h2 className="text-2xl font-bricolage text-black font-bold m-4">
                                    Forgot your password ?
                                </h2>

                                <div className="w-full">
                                    <p>
                                        Don't panick, we'll send you a link to reset it.
                                    </p>
                                    <p>
                                        Please, enter your email address below.
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                                    <input
                                        className="w-full h-full p-2 rounded-sm bg-white border-[1px] border-primary"
                                        placeholder="Email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        />
                                    <Button>
                                        Reset password
                                    </Button>
                                </form>

                                <div className="w-full font-light">
                                    <p>
                                        You didn't receive the email ? You still can't connect or you forgot your email address ?
                                    </p>
                                    <p>
                                        Check your spam folder or &nbsp;
                                        <a href="/contact/message" className="underline text-accent-standard hover:text-accent-active font-bold">contact us</a>.
                                    </p>
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-[80vh] w-full bg-white">
                             <div className="flex flex-col gap-6 max-w-[360px] p-4">

                                <FaCheckDouble className="text-6xl text-accent-standard mx-auto"/>

                                <h2 className="text-2xl font-bricolage text-black font-bold m-4">
                                    Email sent!
                                </h2>

                                <p>
                                    We sent you an email with a link to reset your password.
                                    You can close this tab. See you soon!
                                </p>
                             </div>
                        </div>
                    )
                }
            </>
        );
}


export default PasswordForgotten;
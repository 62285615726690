import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const ImageSlider = (props) => {

    const FIRST_IMAGE = {
        imageUrl: 'https://www.cia-france.com/media/342/nice-ville_1100x700.jpg'
      };
      const SECOND_IMAGE = {
        imageUrl: 'https://www.cia-france.com/media/342/nice-ville_2200x1400.jpg'
      };

    return(
      <ReactCompareSlider
          itemOne={<ReactCompareSliderImage src={props?.image1 || FIRST_IMAGE.imageUrl} alt="Image one" />}
          itemTwo={<ReactCompareSliderImage src={props?.image2 || SECOND_IMAGE.imageUrl} alt="Image two" />}
          className={"rounded-lg shadow-xl transition hover:border-violet-800/10 hover:shadow-violet-500/10 cursor-pointer " + (props.className || "")}
      />
    );

};

export default ImageSlider;

import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonLink from '../../common/buttonLink';
import Button from '../../common/button';
import useOutsideClickEvent from '../../../hooks/useOutsideClickEvent';
import AuthContext from '../../../context/AuthProvider';

const ProfileDropdown = (props) => {

    const {auth, logout} = useContext(AuthContext);
    const profileDropdown = useRef(null);
    const navigate = useNavigate();

    useOutsideClickEvent(profileDropdown, () => {
        props.setIsLoginModalOpen(false);
        props.setIsRegisterModalOpen(false);
        props.setOpen(false);
    });


    const handleLoginClick = () => {
        props.setIsLoginModalOpen(true);
        props.setOpen(false);
    }

    const handleRegister = () => {
        props.setIsRegisterModalOpen(true);
        props.setOpen(false);
    }

    const handleCreateClick = () => {
        navigate('/create');
        props.setOpen(false);
    }

    const handleAccountClick = () => {
        navigate('/account');
        props.setOpen(false);
    }

    const handleOrdersClick = () => {
        navigate('/orders');
        props.setOpen(false);
    }


    return(
        <>
            <div className="absolute flex flex-col right-0 text-left bg-black w-[300px] max-h-[300px] z-[15] p-4 shadow-2xl gap-2"
                 ref={profileDropdown}
            >
                {
                    !auth ? (
                                <div className="flex flex-col gap-2">
                                    <Button onClick={() => handleLoginClick()}>
                                        Login
                                    </Button>
                                    <nav className="flex flex-row text-white text-sm">Dont't have an account yet ?&nbsp;&nbsp; <nav className="text-accent-standard cursor-pointer" onClick={()=>handleRegister()}>Sign up!</nav></nav>

                                </div>
                            ) : (
                                <>
                                    <div className="sm:hidden block">
                                        <Button onClick={() => handleCreateClick()}>
                                            Start creating
                                        </Button>
                                    </div>
                                    <div className='text-md text-white hover:underline hover:decoration-solid decoration-white hover:underline-offset-4 cursor-pointer'
                                         onClick={()=> handleAccountClick() }>
                                        Account
                                    </div>
                                    <div className='text-md text-white hover:underline hover:decoration-solid decoration-white hover:underline-offset-4 cursor-pointer'
                                         onClick={() => handleOrdersClick()}
                                    >
                                        Orders
                                    </div>
                                    <div>
                                        <hr className='border-white border-opacity-50'/>
                                    </div>
                                    <div className='text-md text-white hover:underline hover:decoration-solid decoration-white hover:underline-offset-4 cursor-pointer'
                                         onClick={(e)=>logout()}
                                    >
                                        Logout
                                    </div>
                                </>
                            )
                }

            </div>
        </>
    );
}

export default ProfileDropdown;

import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

import BasketContext from '../../../context/BasketProvider';

import { CiClock1 } from "react-icons/ci";
import { BsTruck } from "react-icons/bs";
import { RiRefund2Line } from "react-icons/ri";
import { GoPlus } from "react-icons/go";

import RefundPolicy from '../../policies/refundPolicy';
import Steps from '../steps';
import Option from './option';

import Button from '../../common/button';
import Wall from './wall';

import { AddItemToBasket } from '../../../api/basket';
import { getDetails } from '../../../api/canvas';
import { get_ai_sizes_and_price } from '../../../api/sizes';
import Loader from '../../loader/loader';
import Shipping from '../../policies/shipping';

const Canvas = () => {

    const [step, setStep] = useState({
        stepItems: ["Create", "Make it a canvas", "Checkout"],
        currentStep: 1
    })

    const navigate = useNavigate();

    const {basketContent, basketId, loaded,
           basketDropdownOpen, setBasketDropdownOpen,
           fetchBasket} = useContext(BasketContext);

    const { ImageId } = useParams();
    const [url, setUrl] = useState(null);
    const [imageIdToUse, setImageIdToUse] = useState(ImageId);
    const [imageDetails, setImageDetails] = useState(null);
    const [imageDetailsLoaded, setImageDetailsLoaded] = useState(false);

    const [size_and_price, setSize_and_price] = useState([])
    const [size_and_price_loaded, setSizeAndPriceLoaded] = useState(false)

    const [showRefundPolicity, setShowRefundPolicity] = useState(false);
    const [showShippingInfo, setShowShippingInfo] = useState(false);

    const [priceIndex, setPriceIndex] = useState(0);

    useEffect(()=>{
        setImageIdToUse(ImageId);
    }, [ImageId]);

    useEffect(()=>{
        getDetails(imageIdToUse).then((res)=>{
            setImageDetails(res.data);
            setUrl(res.data.canvas_url);
            setImageDetailsLoaded(true);
            get_ai_sizes_and_price(res.data.width, res.data.height).then((res)=>{
                let sizes = res.data.list;
                sizes.sort((a, b)=>{
                    return a.price - b.price;
                });
                setSize_and_price(res.data.list);
                setSizeAndPriceLoaded(true);
            }).catch((err)=>{
                console.log(err);
            });
        }).catch((err)=>{
            navigate("/create")
        });
    }, [imageIdToUse])

    const handleAddToBasket = () => {

        ReactGA.event({
            category: "Button",
            action: "Add to basket",
            label: "Add to basket button clicked"
        });

        ReactPixel.track('AddToCart', {
            value: size_and_price[priceIndex].price,
            currency: 'EUR'
        });

        TiktokPixel.track('AddToCart', {
            value: size_and_price[priceIndex].price,
            currency: 'EUR'
        });

        AddItemToBasket(imageIdToUse,
                        size_and_price[priceIndex].width,
                        size_and_price[priceIndex].height,
                        size_and_price[priceIndex].price,
                        size_and_price[priceIndex].id)
            .then((res)=>{
                fetchBasket();
                setBasketDropdownOpen(true);
            })
            .catch((err)=>{
                console.log(err);
            })
    }

    const handleCheckoutDirectly = () => {

        ReactGA.event({
            category: "Button",
            action: "Direct Checkout",
            label: "Direct checkout button clicked"
        });

        ReactPixel.track('InitiateCheckout', {
            value: size_and_price[priceIndex].price,
            currency: 'EUR'
        });

        AddItemToBasket(imageIdToUse,
            size_and_price[priceIndex].width,
            size_and_price[priceIndex].height,
            size_and_price[priceIndex].price,
            size_and_price[priceIndex].id)
        .then((res)=>{
            fetchBasket();
            navigate("/checkout");
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    return (
        <>
            <div className="bg-white w-full flex flex-row">
                <div className="w-full h-full flex flex-col items-center"
                        >
                    <div className="w-full p-4">
                        <Steps
                            step={step}
                            setStep={setStep}
                            />
                    </div>
                        <div className="group lg:relative w-full h-full">

                            <Wall
                                url={url}
                                sizeIndex={priceIndex}
                            />
                            <div className="flex lg:absolute lg:top-0 lg:right-[10px] lg:h-full lg:justify-end w-full lg:w-[30%] items-center">
                                <div className="flex flex-col w-full gap-6 p-8 bg-white rounded-lg">
                                    <div className="text-primary">
                                        <h1 className="text-xl font-bold">Choose your size</h1>
                                        <p className="text-md text-primary mt-3 line-clamp-1">
                                            {
                                                imageDetailsLoaded && imageDetails.positive_prompt
                                            }
                                        </p>
                                    </div>
                                    <div className="flex flex-col w-full justify-center gap-4">
                                        {
                                            size_and_price_loaded ? (
                                                size_and_price.map((size_price, index)=>{
                                                        return(
                                                            <Option
                                                                key={index}
                                                                price={size_price}
                                                                priceIndex={priceIndex}
                                                                setPriceIndex={setPriceIndex}
                                                                index={index}
                                                                />
                                                    )
                                                })
                                            ):
                                            (
                                                <Loader />
                                            )
                                        }
                                    </div>

                                    <div className="w-full h-full flex flex-col gap-2">
                                        {
                                            size_and_price_loaded && (
                                                <Button
                                                    onClick={()=>handleAddToBasket()}
                                                >
                                                    Add to the basket ({size_and_price[priceIndex].price.toFixed(2)} €)
                                                </Button>
                                            )
                                        }
                                        {
                                            size_and_price_loaded && (
                                                <Button
                                                    onClick={()=>handleCheckoutDirectly()}
                                                    blue={true}
                                                    className="text-white"
                                                >
                                                    Checkout directly ({size_and_price[priceIndex].price.toFixed(2)} €)
                                                </Button>
                                            )
                                        }
                                        <div className="flex justify-center items-center text-primary text-sm">
                                            <CiClock1 className="w-[15px] h-[15px] m-2" />
                                            4-6 business days delivery in Europe and North-America.
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex p-4 justify-between items-center text-primary text-md font-extralight cursor-pointer"
                                                onClick={()=>setShowShippingInfo(true)}
                                        >
                                            <BsTruck className="w-[20px] h-[20px]" />
                                            <p>
                                                Shipping
                                            </p>
                                            <GoPlus className="w-[20px] h-[20px]" />
                                        </div>
                                        <div className="flex p-4 border-t border-secondary justify-between items-center text-primary text-md font-extralight cursor-pointer"
                                                onClick={()=>setShowRefundPolicity(true)}
                                        >
                                            <RiRefund2Line className="w-[20px] h-[20px]" />
                                            <p>
                                                7 days refund
                                            </p>
                                            <GoPlus className="w-[20px] h-[20px]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            {
                showRefundPolicity &&
                <RefundPolicy
                    setOpen={setShowRefundPolicity}
                />
            }
            {
                showShippingInfo &&
                <Shipping
                    setOpen={setShowShippingInfo}
                />
            }
        </>
    )
}

export default Canvas;


import React, { useState } from 'react';
import DemoSlider from './demoslider';

import woman_original from "../../assets/demo/woman_original.webp";
import woman_art from "../../assets/demo/woman_art.webp";
import amsterdam_original from "../../assets/demo/amsterdam_original.webp";
import amsterdam_art from "../../assets/demo/amsterdam_art.webp";
import labrador_original from "../../assets/demo/labrador_original.webp";
import labrador_anime from "../../assets/demo/labrador_anime.webp";


const Demo = () => {

    const images = [
        {
            image1: labrador_original,
            image2: labrador_anime
        },
        {
            image1: woman_original,
            image2: woman_art
        },
        {
            image1: amsterdam_original,
            image2: amsterdam_art
        }
    ];

    const [indexPicked, setIndexPicked] = useState(0);

    return (
        <React.Fragment>
            <section className="bg-white flex flex-col items-center justify-center gap-2 py-[25px] lg:px-[200px] px-0">
                <div className="text-center">
                    <h2 className="font-extrabold font-bricolage text-accent-standard bg-clip-text text-3xl font-bold sm:text-4xl ">
                        Infinite possibilities
                    </h2>
                </div>
                <div className="flex flex-col w-full h-full">
                    <div className="sm:p-0 p-1 w-full h-full">
                        <DemoSlider
                            className="h-[400px] sm:h-[500px] max-w-[800px] mx-auto"
                            image1={images[indexPicked].image1}
                            image2={images[indexPicked].image2}
                            />
                    </div>
                    <div className="flex flex-row justify-center gap-2 p-2">
                        {
                            images.map((e, key)=>{
                                return(
                                    <div key={key} onClick={()=>setIndexPicked(key)} className="hover:shadow-2xl cursor-pointer">
                                        <img src={e.image2} className='max-h-[100px] rounded-lg' alt="Canvique AI Demo thumbnail" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </React.Fragment>
    );

};

export default Demo;

import React, { useContext, useEffect } from 'react';
import { AiOutlineShoppingCart } from "react-icons/ai";

import BasketContext from '../../context/BasketProvider';
import BasketDropdown from './basketDropdown';


const Basket = () => {

    const {basketContent, basketId, loaded, basketDropdownOpen, setBasketDropdownOpen, fetchBasket} = useContext(BasketContext);

    const handleOpen = (e) => {
        e.preventDefault();
        setBasketDropdownOpen(!basketDropdownOpen);
    }

    useEffect(() => {
        fetchBasket();
    },[]);

    return (
        <>
            <div className="group relative">
                <button className="
                                text-white px-5 py-2.5 text-[1.3rem] rounded-md
                                duration-25 active:enabled:translate-x-0.5 
                                active:enabled:translate-y-0.5
                                bg-accent2-standard hover:bg-accent2-active 
                                hover:cursor-pointer"
                        onClick={(e) => handleOpen(e)}
                >
                    <AiOutlineShoppingCart className=""/>
                    {
                        loaded && <span className="absolute text-xs text-black bottom-[-3px] right-[-3px]
                                                    pt-[2px] pl-[7px] pr-[7px] pb-[1px]
                                                    bg-white rounded-full">
                                        {basketContent.length}
                                    </span>
                    }
                </button>
                {
                    basketDropdownOpen && (
                        <>
                            <BasketDropdown 
                                setOpen={setBasketDropdownOpen}
                            />
                        </>
                    )
                }
            </div>
        </>
    )
}


export default Basket;

import React, { useState, useEffect, useRef } from 'react';

import beige_wall from '../../../assets/walls/beige_wall.jpg';
import blue_wall from '../../../assets/walls/blue_wall.jpg';
import green_wall from '../../../assets/walls/green_wall.jpg';
import grey_wall from '../../../assets/walls/grey_wall.jpg';
import yellow_wall from '../../../assets/walls/yellow_wall.jpg';

const Wall = (props) => {

    const walls = [
        {id: "beige_wall", src: beige_wall},
        {id: "blue_wall", src: blue_wall},
        {id: "green_wall", src: green_wall},
        {id: "grey_wall", src: grey_wall},
        {id: "yellow_wall", src: yellow_wall},
    ];

    const [indexPreview, setIndexPreview] = useState(0);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    const [stylesDiv, setStylesDiv] = useState({
        transition: 'top 200ms, left 200ms',
        top: "20%",
        left: "32%"
    });

    const [stylesImg, setStylesImg] = useState({
        transition: 'width 200ms, height 200ms',
        width: "auto",
        height: "auto"
    });

    const [imageOriginalWidth, setImageOriginalWidth] = useState(0);
    const [imageOriginalHeight, setImageOriginalHeight] = useState(0);

    const overlayImageRef = useRef(null);

    const sizes = [
        {size: 250},
        {size: 300},
        {size: 400},
    ];

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

    }

    useEffect(()=>{
        window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(()=>{

        if(imageOriginalWidth > imageOriginalHeight){

            let w = Math.min(sizes[props.sizeIndex].size, windowSize.width - 16);

            setStylesImg({
                transition: 'width 200ms, height 200ms',
                width: w+"px",
                maxWidth: "100%",
                height: "auto"
            });

            if(windowSize.width < 1024){
                var left = Math.max(0, windowSize.width/2 - w/2);
            }else{
                var left = Math.max(0, windowSize.width/3 - w/2);
            }

            setStylesDiv({
                transition: 'top 200ms, left 200ms',
                left: Math.round(left)+"px",
                top: "20%"
            });

        } else {

            let h = Math.min(sizes[props.sizeIndex].size, windowSize.height - 16);

            setStylesImg({
                transition: 'width 200ms, height 200ms',
                width: "auto",
                height: h+"px",
                maxHeight: "100%"
            });

            if(windowSize.width < 1024){
                var left = Math.max(0, windowSize.width/2 - Math.floor(imageOriginalWidth/imageOriginalHeight*h)/2);
            }else{
                var left = Math.max(0, windowSize.width/3 - Math.floor(imageOriginalWidth/imageOriginalHeight*h)/2);
            }

            setStylesDiv({
                transition: 'top 200ms, left 200ms',
                left: Math.round(left)+"px",
                top: "20%"
            });

    }

    }, [props.sizeIndex, windowSize, imageOriginalWidth, imageOriginalHeight]);

    const applyStyle = (e) => {

        setImageOriginalWidth(e.width);
        setImageOriginalHeight(e.height);
    }

    return(
        <>
            <div className="w-full h-full">
                <div className="flex flex-col lg:flex-row w-full h-[calc(100vh-128px)]">
                    <div className="group relative flex aspect-square shrink-0 grow flex-col justify-center overflow-hidden bg-white sm:min-h-0 sm:shrink sm:basis-0">
                        <img src={walls[indexPreview].src}
                            alt="Canvique AI wall preview"
                            className={`h-full w-full grow basis-0 object-none lg:object-cover sm:h-[999999px] sm:min-h-0 sm:w-[999999px] opacity-100`}
                            />
                        <div className="absolute transition duration-100 shadow-2xl"
                                style={stylesDiv}
                        >
                            <img src={props.url} className="transition duration-100 shadow-2xl"
                                ref={overlayImageRef}
                                alt="Canvique AI image preview"
                                onLoad={(e)=>applyStyle(e.target)}
                                style={stylesImg}
                            />
                        </div>
                    </div>
                    <div className="flex lg:absolute lg:top-0 lg:left-[10px] lg:h-full justify-center items-center">
                        <div className="flex flex-row lg:flex-col
                                        mt-4 gap-2
                                        md:gap-4 lg:gap-6 overflow-x-scroll">
                            {
                                walls.map((wall, index)=>{

                                    return(
                                        <div className="group relative
                                                        min-h-[100px] min-w-[100px]
                                                        max-h-[100px] max-w-[100px]
                                                        h-[100px] w-[100px]
                                                        bg-white rounded-sm
                                                        border-2 border-white hover:shadow-md hover:border-black"
                                                onClick={()=>setIndexPreview(index)}
                                                key={index}
                                        >
                                            <img src={wall.src}
                                            className={`w-full h-full object-cover`}/>
                                            <img className='absolute h-[55px] max-w-[70px] object-scale-down top-[13px] left-[23px]' src={props.url}>
                                            </img>
                                        </div>
                                        );
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Wall;
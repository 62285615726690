
import React from 'react';


const PageReturnPolicy = () => {

    return(
        <>
            <div className="flex items-center min-h-screen px-4 py-8 bg-white">
                <div className="relative w-full max-w-2xl p-4 py-8 mx-auto rounded-md shadow-lg">
                    <div className="mt-3">
                        <div className="flex flex-col gap-1 mt-2 text-center text-black">

                            <h1 className="text-2xl font-bold">
                                Return Policy
                            </h1>

                            <div className="mt-4 text-justify">
                                Thank you for choosing Canvique.
                                Returns are only accepted for products that are in their original condition.
                                Customers must initiate the return process within seven (7) days of receiving the product.
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                Return Process
                            </h3>

                            <div className="mt-4 text-justify">
                            Before returning any item, please contact our customer support team at <span className="font-bold">support@canvique.ai</span> to obtain a return authorization.
                            Clearly mention "Return Authorization Request - [Your Order Number]" in the subject line.
                            Provide your order number, a detailed reason for the return, and any supporting information in the email.
                            Once your return request is approved, you will receive further instructions on how to return the item.
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                How to Request a Refund
                            </h3>

                            <div className="mt-4 text-justify">
                                Send an email to <span className="font-bold">support@canvique.ai</span> with the subject line "Refund Request - [Your Order Number]."
                                In the email, provide your order number, a detailed explanation of the reason for the refund, and any supporting documentation (if applicable).
                                Our customer support team will review your request and respond within 2 business days.
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                Conditions for Returns
                            </h3>

                            <div className="mt-4 text-justify">
                                Products must be returned in their original packaging and without any signs of use or damage.
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                Refund Process
                            </h3>

                            <div className="mt-4 text-justify">
                                Upon receiving and inspecting the returned item, Canvique will process the refund using the original payment method.
                                Please allow up to 7 business days for the refund to be reflected in your account.
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                You received the wrong item or the item is damaged
                            </h3>

                            <div className="mt-4 text-justify">
                            If you receive a damaged or the wrong item, please contact our customer support team immediately. We will provide instructions on how to return the item or arrange for a replacement in no time!
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                Contact Information
                            </h3>

                            <div className="mt-4 text-justify">
                                For any questions or concerns regarding our refund policy,
                                please contact us at <span className="font-bold">support@canvique.ai</span>!
                            </div>

                            <h3 className="text-xl mt-2 font-semibold">
                                Changes to Return Policy
                            </h3>

                            <div className="mt-4 text-justify">
                                Canvique reserves the right to modify or update this return policy at any time. Changes will be effective immediately upon posting on our website.
                                By making a purchase from Canvique, you acknowledge that you have read and agree to our return policy.
                                Thank you for choosing Canvique!
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default PageReturnPolicy;
import React, { useEffect, useState } from "react";
import classNames from 'classnames';

const Button = (props) => {

    const {className, ...rest} = props;
    const [classes, setClasses] = useState("duration-25 active:enabled:translate-x-0.5 active:enabled:translate-y-0.5 text-center rounded-md px-5 py-2.5 text-sm font-medium text-black shadow ");
    const [color, setColor] = useState(" bg-accent-standard hover:enabled:bg-accent-active ");

    useEffect(() => {
        if(props.disabled){
            setColor(" bg-accent-disabled ");
        }
        if(props.delete){
            setColor(" bg-delete-standard hover:enabled:bg-delete-active cursor-pointer ");
        }
        if(props.blue){
            setColor(" bg-accent2-standard hover:enabled:bg-accent2-active cursor-pointer ");
        }
    }, [props.disabled, props.delete, props.blue]);

    return (
        <button
        className={classes + color + className}
        disabled={props.disabled}
        {...rest}
        >
            {rest.children}
        </button>
    );
};

export default Button;
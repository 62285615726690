
import React from "react";
import NewsletterCTA from "../contact/cta";


const Newsletter = () => {

    return(
        <section className="bg-black" id="newsletter">
            <div className="py-24 px-8 max-w-4xl mx-auto flex flex-col gap-8">
                <h1 className="text-2xl font-bold text-accent-standard text-center">
                    Newsletter
                </h1>
                <p className="text-white font-light">
                    Subscribe to Canvique.ai's newsletter for exclusive access to new art styles, promotions, and creative inspiration. Join now and stay connected to the world of AI-generated masterpieces
                </p>
                <div className="flex justify-center text-center">
                    <NewsletterCTA />
                </div>
            </div>
        </section>
    )
}

export default Newsletter;

import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const getValidationToken = (token) => {

    const obj = {
        token: token
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/validate",
                                    {
                                        params: obj
                                    });
}

export const getAccount = () => {
    const token = localStorage.getItem("accessToken");
    const obj = {
        token: token
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/account",
                                    {
                                        params: obj
                                    });
}


export const postRegister = (name,
                             email,
                             password,
                             check) => {

    const obj = JSON.stringify({
        name: name,
        email: email,
        password: password});

        return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/register",
                                    obj,
                                    {
                                        headers: { 'Content-Type': 'application/json' },
                                        withCredentials: true
                                    });
};


export const changePassword = (oldPassword,
                                 newPassword) => {
    
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('old_password', oldPassword);
    formData.append('new_password', newPassword);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/change_password",
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
            crossDomain: true
        });
}

export const changeAddress = (
    full_name,
    address_line_1,
    address_line_2,
    country_id,
    postal_code,
    city,
    contact_phone,
    state_province
) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('full_name', full_name);
    formData.append('address_line_1', address_line_1);
    formData.append('address_line_2', address_line_2);
    formData.append('country_id', country_id);
    formData.append('postal_code', postal_code);
    formData.append('city', city);
    formData.append('contact_phone', contact_phone);
    formData.append('state_province', state_province);

    return axios.put(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/change_address",
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
            crossDomain: true
        });
}

export const postLogin = (email,
                          password) => {

    const obj = JSON.stringify({
        email: email,
        password: password});

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/login",
                                    obj,
                                    {
                                        headers: { 'Content-Type': 'application/json' },
                                        withCredentials: true
                                    });

}


export const postForgotPassword = (email) => {
    
        const formData = new FormData();
        formData.append('email', email);
    
        return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/forgot_password",
                            formData,
                            {
                                headers: { 'Content-Type': 'multipart/form-data' },
                                withCredentials: true,
                                crossDomain: true
                            });
    
    }

export const postResetPassword = (password1, password2, token) => {
    
    const formData = new FormData();
    formData.append('password1', password1);
    formData.append('password2', password2);
    formData.append('token', token);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/reset_password",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
}

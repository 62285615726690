
import React from 'react';

import instagrampng from "../../assets/instagram.png";
import facebookpng from "../../assets/facebook.png";
import Link from "../common/link";
import "./footer.css";

const Footer = () => {

    const company = [
      {
        "title": "About",
        "link": "#",
      },
      {
        "title": "Meet the Team",
        "link": "#",
      },
      {
        "title": "Accounts Review",
        "link": "#",
      }
    ]

    const helpfulLinks = [
      {
        "title": "Contact us",
        "link": "/contact/message",
      },
      {
        "title": "Business Inquiries",
        "link": "/contact/business-inquiries",
      },
      {
        "title": "Frequently Asked Questions",
        "link": "/#faq",
      }
    ]

    const legal = [
      {
        "title": "Terms & Conditions",
        "link": "/legal/terms-and-conditions",
      },
      {
        "title": "Return Policy",
        "link": "/legal/return-policy",
      },
      {
        "title": "Refund Policy",
        "link": "/legal/refund-policy",
      },
      {
        "title": "Privacy Policy",
        "link": "/legal/privacy-policy",
      }
    ]

    return(
<footer className="bg-primary pt-[1rem]">
  <div
    className="mx-auto max-w-screen-xl space-y-8 px-4 py-8 sm:px-6 lg:space-y-10 lg:px-8"
  >
    <div
      className="grid grid-cols-1 gap-8 border-t border-accent-standard pt-8 sm:grid-cols-2 lg:grid-cols-3 lg:pt-16"
    >
      <div>
        <p className="font-medium text-white">Company</p>

        <ul className="mt-6 space-y-4 text-sm">
          {
            company.map((service) => {
              return (
                <li key={service.title}>
                  <Link href={service.link}>
                    {service.title}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div>
        <p className="font-medium text-white">Helpful Links</p>
        <div className="flex flex-row gap-2">
          <a href="https://www.facebook.com/profile.php?id=61556311814438">
            <img src={facebookpng} alt="Instagram" className="w-[40px] h-[40px]" />
          </a>

          <a href="https://www.instagram.com/canvique.ai?igsh=MW15ZHU3eHhpZjEweg%3D%3D">
            <img src={instagrampng} alt="Instagram" className="w-[40px] h-[40px]" />
          </a>
        </div>

        <ul className="mt-6 space-y-4 text-sm">
        {
            helpfulLinks.map((service) => {
              return (
                <li key={service.title}>
                  <Link href={service.link}>
                    {service.title}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div>
        <p className="font-medium text-white">Legal</p>

        <ul className="mt-6 space-y-4 text-sm">
        {
            legal.map((service) => {
              return (
                <li key={service.title}>
                  <Link href={service.link}>
                    {service.title}
                  </Link>
                </li>
              )
            })
        }
        </ul>
      </div>
    </div>

    <p className="text-xs text-white">
      &copy; 2022. Canvique. All rights reserved.
    </p>
  </div>
</footer>
    );

}

export default Footer;
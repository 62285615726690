
import React, { useState, useEffect } from "react";
import Loader from "../loader/loader";
import { useNavigate, Link } from "react-router-dom";

import { getOrders, getDeliveredOrders } from "../../api/basket";
import Button from "../common/button";


const Order = (props) => {

    const openExternalLink = (externalURL) => {

        if (!externalURL.startsWith('https://') && !externalURL.startsWith('http://')) {
          externalURL = 'https://' + externalURL;
        }

        window.open(externalURL, '_blank');
      };


    return(
        <>
            <div className="flex flex-col w-full h-full rounded-lg border-[1px] border-primary">
                <div className="flex flex-col bg-white text-black rounded-t-lg">
                    <div className="p-2 flex flex-row justify-between">
                        <div className="flex flex-col justify-between">
                            <span className="text-xl font-bold text-primary">
                                Order ID : <span className="text-sm font-light">#{props.order.basket_id}</span>
                            </span>
                            <span className="text-black font-medium">
                                Order date <span className="font-light text-primary">{new Intl.DateTimeFormat('en-GB', {dateStyle: 'full'}).format(props.order.order_date*1000)}</span>
                            </span>
                            <span className="text-black font-medium">
                                Estimated arrival date <span className="font-light text-primary">{new Intl.DateTimeFormat('en-GB', {dateStyle: 'full'}).format((props.order.order_date+props.order.shipping_delay)*1000)}</span>
                            </span>
                            <span className="text-black font-bold">
                                A problem ? <a className="text-black font-semibold" href={`/contact/support/${props.order.basket_id}`}>Contact us</a>
                            </span>
                            <span className="text-black font-bold">
                                Status : <span className="text-black font-semibold">{props.order.status}</span>
                            </span>
                        </div>
                        <div className="h-full flex items-center justify-center">
                            <Button
                                disabled={props.order.status !== "shipped"}
                                onClick={() => openExternalLink(props.order.order_tracking_url)}
                                >
                                Track order
                            </Button>
                        </div>
                    </div>
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="w-full h-[250px] overflow-y-scroll overflow-x-hidden">
                    {
                        props.order.items.map((item, index) => {
                            return(
                                <>
                                    <OrderItem
                                        key={index}
                                        image_id={item.image_id}
                                        prompt={item.prompt}
                                        width={item.width}
                                        height={item.height}
                                        price={item.price}
                                        url={item.snapshot_url}
                                        />
                                </>
                            );
                        })
                    }
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="flex flex-row justify-between text-left text-black p-2">
                    <div className="flex flex-col basis-1/3">
                        <h3 className="font-semibold text-lg">
                            Shipping Details
                        </h3>
                        <p>
                            Delivery method : FedEx
                        </p>
                    </div>
                    <div className="basis-1/3">
                    </div>
                    <div className="flex flex-col basis-1/3">
                        <p>
                            <span className="font-semibold text-md">Full name</span> : <span>{props.order.full_name}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Address Line 1</span> : <span>{props.order.address_line_1}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Address Line 2</span> : <span>{props.order.address_line_2}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">City</span> : <span>{props.order.city}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">state_province</span> : <span>{props.order.state}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Postal Code</span> : <span>{props.order.postal_code}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Country</span> : <span>{props.order.country}</span>
                        </p>
                    </div>
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="flex flex-row justify-between bg-white w-full text-left p-4 rounded-b-lg">
                    <div className="basis-1/3">
                        <span className="font-semibold text-md text-black">
                            Payment info
                        </span>
                    </div>
                    <div className="basis-1/3">

                    </div>
                    <div className="flex flex-col text-left basis-1/3">
                        <div>
                            <span className="font-semibold text-md text-black">Subtotal : </span>
                            <span className="font-bold">{
                                props.order.items.reduce((a, b) => a + b.price, 0).toFixed(2)
                            }€</span>
                        </div>
                        <div>
                            <span className="font-semibold text-md text-black">Shipping : </span>
                            <span className="font-bold">{
                                props.order.shipping_price.toFixed(2)
                            }€</span>
                        </div>
                        <div>
                            <span className="font-semibold text-md text-black">Total : </span>
                            <span className="font-bold">{
                                (props.order.items.reduce((a, b) => a + b.price, 0) + props.order.shipping_price).toFixed(2)
                            }€</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};


const OrderItem = (props) => {

    const navigate = useNavigate();

    return(
        <>
            <div className="group relative flex flex-row w-full gap-2 p-2">
                <div className="bg-black cursor-pointer hover:brightness-50 min-w-[150px] basis-2/12"
                        onClick={()=>navigate("/canvas/"+props.image_id)}
                >
                    <img alt="Couldn't load this image :("
                        src={props.url}
                        className="w-[150px] h-[150px] object-contain"
                        />
                </div>
                <div className="basis-8/12">
                    <nav className="line-clamp-6">
                        {
                            props.prompt
                        }
                    </nav>
                </div>
                <div className="flex flex-col basis-2/12">
                    <nav className="text-lg font-bold">
                        {
                            props.price + " €"
                        }
                    </nav>
                    <nav className="text-lg">
                        {
                            props.width + " x " + props.height + "cm"
                        }
                    </nav>
                </div>
                <div>
                </div>
            </div>
        </>
    );

};


const Orders = () => {

    const [currentOrders, setcurrentOrders] = useState([]);
    const [deliveredOrders, setDeliveredOrders] = useState([]);

    const [currentOrdersLoading, setCurrentOrdersLoading] = useState(true);
    const [deliveredOrdersLoading, setDeliveredOrdersLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getOrders()
            .then((response) => {
                setcurrentOrders(response.data.orders);
                setCurrentOrdersLoading(false);
            })
            .catch((error) => {
                if(error.response.status !== 404){
                    console.log(error);
                }
            });

        getDeliveredOrders()
            .then((response) => {
                setDeliveredOrders(response.data.orders);
                setDeliveredOrdersLoading(false);
            })
            .catch((error) => {
                if(error.response.status !== 404){
                    console.log(error);
                }
            });
    }, []);

    return (
        <>
            <div className="bg-white w-full">
                <div className="flex flex-col justify-center gap-2 w-full md:px-16 py-16 px-1 h-full align-left">
                    <div className="w-full flex flex-row justify-between">
                        <h3 className="text-3xl font-bold text-black">Orders</h3>
                    </div>
                    <hr className="border-black border-opacity-50"/>
                    <div className="flex flex-col items-center w-full">
                        <h4 className="text-2xl w-full text-left font-bold text-black mb-4">Current Orders</h4>
                        <div className="flex flex-col max-w-4xl gap-4">
                            {
                                currentOrdersLoading ? (
                                    <Loader />
                                ) : (
                                    currentOrders.length === 0 ? (
                                        <p>
                                            No delivered orders yet.
                                        </p>
                                    ) : (

                                        currentOrders.map((order, index) => {
                                            return(
                                                <>
                                                    <Order order={order} />
                                                </>
                                        );
                                        })
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col items-center w-full">
                        <h4 className="text-2xl w-full text-left font-bold text-black">Delivered Orders</h4>
                        <div className="flex flex-col max-w-4xl gap-4">
                            {
                                deliveredOrdersLoading ? (
                                    <Loader />
                                ) : (
                                    deliveredOrders.length === 0 ? (
                                        <p>
                                            No delivered orders yet.
                                        </p>
                                    ) : (

                                        deliveredOrders.map((order, index) => {
                                            return(
                                                <>
                                                <Order order={order} />
                                            </>
                                        );
                                        })
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Orders;

import React, { useState, useEffect } from 'react';

import { get_processing_orders } from '../../api/admin';
import Loader from "../loader/loader";
import Button from "../common/button";

import { update_order } from '../../api/admin';


const Order = (props) => {

    const [status, setStatus] = useState(props.order.status);
    const [tracking, setTracking] = useState(props.order.order_tracking_url);

    const handleUpdate = () => {
        update_order(props.order.basket_id, status, tracking).then((response) => {
            props.fetchOrders();
        }).catch((error) => {
            console.log(error);
        });
    }

    return(
        <>
            <div className="flex flex-col max-w-[1000px] h-full rounded-lg border-[1px] border-primary">
                <div className="flex flex-col bg-white text-black rounded-t-lg">
                    <div className="p-2 flex flex-row justify-between">
                        <div className="flex flex-col justify-between">
                            <span className="text-xl font-bold text-primary">
                                Order ID : <span className="text-sm font-light">#{props.order.basket_id}</span>
                            </span>
                            <span className="text-black font-medium">
                                Order date <span className="font-light text-primary">{new Intl.DateTimeFormat('en-GB', {dateStyle: 'full'}).format(props.order.order_date*1000)}</span>
                            </span>
                            <span className="text-black font-medium">
                                Estimated arrival date <span className="font-light text-primary">{new Intl.DateTimeFormat('en-GB', {dateStyle: 'full'}).format((props.order.order_date+props.order.shipping_delay)*1000)}</span>
                            </span>
                            <span className="text-black font-bold">
                                Status :
                                <select
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                >
                                    <option value="processing">Processing</option>
                                    <option value="shipped">Shipped</option>
                                    <option value="delivered">Delivered</option>
                                </select>
                            </span>
                            <Button onClick={()=>handleUpdate()}>
                                Update
                            </Button>
                        </div>
                        <div className="h-full flex items-center justify-center">
                            <input type="text"
                                value={tracking}
                                onChange={(e) => setTracking(e.target.value)}
                                placeholder="Tracking URL"
                                className="w-full h-full p-2 border-[1px] border-primary rounded-lg"
                            />
                        </div>
                    </div>
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="w-full h-[250px] overflow-y-scroll overflow-x-hidden">
                    {
                        props.order.items.map((item, index) => {
                            return(
                                <>
                                    <OrderItem
                                        key={index}
                                        image_id={item.image_id}
                                        prompt={item.prompt}
                                        width={item.width}
                                        height={item.height}
                                        price={item.price}
                                        url={item.large_url}
                                        />
                                </>
                            );
                        })
                    }
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="flex flex-row justify-between text-left text-black p-2">
                    <div className="flex flex-col basis-1/3">
                        <h3 className="font-semibold text-lg">
                            Shipping Details
                        </h3>
                        <p>
                            Delivery method : FedEx
                        </p>
                    </div>
                    <div className="basis-1/3">
                    </div>
                    <div className="flex flex-col basis-1/3">
                        <p>
                            <span className="font-semibold text-md">Full name</span> : <span>{props.order.full_name}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Address Line 1</span> : <span>{props.order.address_line_1}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Address Line 2</span> : <span>{props.order.address_line_2}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">City</span> : <span>{props.order.city}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">state_province</span> : <span>{props.order.state}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Postal Code</span> : <span>{props.order.postal_code}</span>
                        </p>
                        <p>
                            <span className="font-semibold text-md">Country</span> : <span>{props.order.country}</span>
                        </p>
                    </div>
                </div>
                <hr className="border-black border-opacity-50"/>
                <div className="flex flex-row justify-between bg-white w-full text-left p-4 rounded-b-lg">
                    <div className="basis-1/3">
                        <span className="font-semibold text-md text-black">
                            Payment info
                        </span>
                    </div>
                    <div className="basis-1/3">

                    </div>
                    <div className="flex flex-col text-left basis-1/3">
                        <div>
                            <span className="font-semibold text-md text-black">Subtotal : </span>
                            <span className="font-bold">{
                                props.order.items.reduce((a, b) => a + b.price, 0).toFixed(2)
                            }€</span>
                        </div>
                        <div>
                            <span className="font-semibold text-md text-black">Shipping : </span>
                            <span className="font-bold">{
                                props.order.shipping_price.toFixed(2)
                            }€</span>
                        </div>
                        <div>
                            <span className="font-semibold text-md text-black">Total : </span>
                            <span className="font-bold">{
                                (props.order.items.reduce((a, b) => a + b.price, 0) + props.order.shipping_price).toFixed(2)
                            }€</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};


const OrderItem = (props) => {

    return(
        <>
            <div className="group relative flex flex-row w-full gap-2 p-2">
                <a className="bg-black cursor-pointer hover:brightness-50 min-w-[150px] basis-2/12"
                        href={props.url} target="_blank" rel="noreferrer"
                >
                    <img alt="Couldn't load this image :("
                        src={props.url}
                        className="w-[150px] h-[150px] object-contain"
                        />
                </a>
                <div className="basis-8/12">
                    <nav className="line-clamp-6">
                        {
                            props.prompt
                        }
                    </nav>
                </div>
                <div className="flex flex-col basis-2/12">
                    <nav className="text-lg font-bold">
                        {
                            props.price + " €"
                        }
                    </nav>
                    <nav className="text-lg">
                        {
                            props.width + " x " + props.height + "cm"
                        }
                    </nav>
                </div>
                <div>
                </div>
            </div>
        </>
    );

};



const AdminOrder = () => {

    const [ loading, setLoading ] = useState(true);
    const [ orders, setOrders ] = useState([]);

    const fetchOrders = () => {
        get_processing_orders().then((response) => {
            setOrders(response.data.orders);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchOrders();
    }, []);

    return(
        <>
        {
            loading ? <Loader /> :
            (
                <>
                    <h1 className="text-2xl text-bricolage font-bold">
                        Processing Orders : {orders.length}
                    </h1>
                    <div className="w-full h-full">
                        <div className='flex flex-col items-center gap-4 w-full'>
                            {
                                orders.map((order, index) => {
                                    return(
                                        <Order
                                            key={index}
                                            order={order}
                                            fetchOrders={fetchOrders}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                </>
            )
        }
        </>
    )
}

export default AdminOrder;


import React from 'react';
import { FaRegTrashAlt } from "react-icons/fa";

const ImageUpload = (props) => {

  // Function to handle file upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      props.setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      props.setFile(file);
    }
  };
  // Function to handle props.image deletion
  const handleImageDelete = () => {
    props.setImage(null);
    props.setFile(null);
    props.errorDispatch({type: "updateStrength", payload: true});
    props.setUpdateStrengthError(false);
  };

  return (
    <div className="flex w-full justify-center max-h-[200px] min-h-0 w-fit flex-col gap-4 overflow-y-auto sm:flex-row">
      {
        props.image ? (
          <div className="group relative flex aspect-square shrink-0 grow flex-col justify-center overflow-hidden rounded bg-black sm:min-h-0 sm:shrink sm:basis-0">
            <img src={props.image} alt="Uploaded" className={`h-full w-full grow basis-0 object-contain sm:h-[999999px] sm:min-h-0 sm:w-[999999px] opacity-100`}/>
            <button onClick={handleImageDelete} className="delete-button absolute top-0 right-0">
              <FaRegTrashAlt className="w-5 h-5 m-1 text-white hover:text-secondary"/>
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
              <label htmlFor="dropzone-file" className="flex flex-col items-center rounded-md justify-center w-full h-full cursor-pointer bg-black hover:bg-primary">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg className="w-8 h-8 mb-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 text-gray-400"><span className="font-semibold">Click to upload</span> {window.innerWidth > 640 ? "or drag and drop" : ""}</p>
                      <p className="text-xs text-gray-500 text-gray-400">SVG, PNG, JPG or GIF (MAX. 20Mo)</p>
                  </div>
                  <input id="dropzone-file" onChange={handleImageUpload} type="file" className="hidden" />
              </label>
          </div>
        )
      }
      
    </div>
  );
}

export default ImageUpload;

import React from "react";

const Dropdown = (props) => {

    return (
      <div className="relative w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        <select value={props.value}
                className={`w-full px-3 py-2 text-sm 
                            bg-black hover:bg-primary text-white rounded-lg 
                            shadow-sm outline-none appearance-none
                            ${props.error ? "border-[1px] border-delete-standard" : ""}`}
                {...props}>
            {
                props.title && <option selected disabled>{props.title}</option>
            }
            {
                props.list.map((value, key) => {
                    return (
                        <option key={key} value={key}>{value}</option>
                    )
                })
            }
        </select>
        {
            props.error && (
                <label
                    htmlFor="DropdownError"
                    className="block text-sm font-light text-delete-standard"
                >
                    {props.errorText}
                </label>
            )
        }
      </div>
    );
  };
  
export default Dropdown;
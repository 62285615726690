

import React, { useState } from "react";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";

import { postMessage } from "../../api/contact";

const Message = (props) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleSubmit = () => {
        postMessage(name, email, message)
        .then((res) => {
            navigate("/contact/success");
        })
        .catch((err) => {
            console.log(err);
        })
    }

    return(
        <>
            <div className="py-6 flex flex-row bg-white gap-5 justify-center">
                <div className="w-[800px] h-[800px] flex flex-col justify-center">
                    <h1 className="font-bold text-primary text-4xl">
                        Contact us!
                    </h1>
                    <h3 className="font-semibold text-xl text-primary">
                        A question ? A secret to share ? Let us know!
                    </h3>
                    <div className="flex flex-col gap-2 p-4 text-sm">
                        <div className="w-[50%] min-w-[200px] max-h-[150px]">
                            <input
                                className="w-full h-full p-2 rounded-sm bg-white border-[1px] border-primary"
                                placeholder="Name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                />
                        </div>
                        <div className="w-[50%] min-w-[200px] max-h-[150px]">
                            <input
                                className="w-full h-full p-2 rounded-sm bg-white border-[1px] border-primary"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                />
                        </div>
                        <div className="w-full max-h-[150px]">
                            <textarea
                                className="w-full h-full p-2 min-h-[75px] rounded-sm bg-white border-[1px] border-primary"
                                placeholder="Write your message, your question here..."
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Button onClick={()=>handleSubmit()}>
                                Send!
                            </Button>
                            <label className="text-xs font-light">
                                We will read your message soon and we reach you back as soon as possible!
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Message;
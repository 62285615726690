
import React, { useContext, useRef, useCallback, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";

import BasketContext from '../../context/BasketProvider';
import Button from '../common/button';
import useOutsideClickEvent from '../../hooks/useOutsideClickEvent';
import { RemoveItemFromBasket } from '../../api/basket';

const BasketDropdownItem = (props) => {

    const navigate = useNavigate();

    const handleRemoveFromBasket = (itemId) => {
        RemoveItemFromBasket(itemId)
            .then((res)=>{
                props.fetchBasket();
            })
            .catch((err)=>{
                console.log(err);
            })
    }

    return(
        <>
            <div className="group relative flex flex-row w-full gap-2 p-2">
                <div className="bg-black cursor-pointer hover:brightness-50 min-w-[150px]"
                        onClick={()=>navigate("/canvas/"+props.imageId)}
                >
                    <img alt="Couldn't load this image :("
                        src={props.url}
                        className="w-[150px] h-[150px] object-contain"
                        />
                </div>
                <div className="flex flex-col justify-between text-white py-2">
                    <nav className="line-clamp-3">
                        {
                            props.prompt
                        }
                    </nav>
                    <div>
                        <nav className="text-sm font-light">
                            {
                                props.width + " x " + props.height + "cm"
                            }
                        </nav>
                        <nav className="text-sm font-light">
                            {
                                props.price + " €"
                            }
                        </nav>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="absolute top-2 right-2 text-delete-standard hover:text-delete-active cursor-pointer"
                        onClick={()=>handleRemoveFromBasket(props.itemId)}
                >
                    <FaRegTrashAlt className="w-[15px] h-[15px]"/>
                </div>
            </div>
        </>
    );

};

const BasketDropdown = (props) => {

    const {basketContent, basketId, loaded, fetchBasket} = useContext(BasketContext);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    useOutsideClickEvent(dropdownRef, () => {
        props.setOpen(false);
    });

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            props.setOpen(false);
        }
    }, []);

    useEffect(() => {

        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return(
        <>
            <div className="fixed md:absolute top-[50px] md:top-[45px] right-[0px]
                            min-h-[200px] max-h-[600px] w-[300px] md:w-[400px]
                            bg-primary rounded-md shadow-xl z-[1050]
                            border border-accent2-standard"
                    ref={dropdownRef}
            >
                <div className="flex flex-row justify-between h-full text-accent-standard p-2 border-b-[1px] border-b-secondary">
                    <AiOutlineShoppingCart className="w-[25px] h-[25px]"/>
                    <span className="text-white">
                        Subtotal : <span className="text-accent-standard">{basketContent.reduce((acc, item) => acc + item.price, 0).toFixed(2)} €</span>
                    </span>
                </div>
                <div className="flex flex-col gap-2 overflow-y-scroll min-h-[200px] max-h-[450px] w-full p-2">
                    {
                        basketContent.length > 0 ? (
                            basketContent.map((item, index) => {
                                return(
                                    <BasketDropdownItem
                                        key={index}
                                        url={item.snapshot_url}
                                        itemId={item.item_id}
                                        imageId={item.image_id}
                                        width={item.width}
                                        height={item.height}
                                        price={item.price}
                                        prompt={item.prompt}
                                        fetchBasket={fetchBasket}
                                        />
                                );
                            })
                            ) : (
                                <div className="flex flex-col justify-between items-center h-[100px] text-white p-2">
                                    <div className="">
                                        Your basket is empty
                                    </div>
                                    <Button onClick={()=>{
                                        props.setOpen(false);
                                        navigate("/create")
                                        }}>
                                        Start creating now!
                                    </Button>
                                </div>
                            )
                        }
                </div>
                {
                    basketContent.length > 0 && (
                        <div className="flex flex-col w-full justify-around bg-primary p-4 gap-2">
                            <Button onClick={()=>navigate("/checkout")}>
                                Checkout
                            </Button>
                        </div>
                    )
                }
            </div>
        </>
    );

};

export default BasketDropdown;
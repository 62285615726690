
import React, {useState} from 'react';


const Item = (props) => {

    const [deployed, setDeployed] = useState(props.deployed || false);

    return(
        <>
        <div className="group text-sm flex w-full gap-1 rounded border border-secondary bg-black px-3 py-2">
            <div className="flex w-full flex-col gap-2">
                <div className="flex select-none flex-row items-center">
                    <button className="group relative flex h-fit select-none flex-row items-center justify-center gap-1 whitespace-nowrap rounded align-middle duration-150 w-fit shadow-black/5 cursor-pointer opacity-100 mr-auto p-0" 
                            onClick={() => setDeployed(!deployed)}> 
                        <div className="flex items-center opacity-75 duration-100 group-hover:opacity-100 shrink h-6 w-6 origin-center mr-1 -ml-1 p-0">
                            {
                                deployed ? (
                                        props.deployedicon
                                    ) : (
                                        props.undeployedicon
                                    )
                            }
                        </div>
                        <div className="w-full font-light text-white">
                            {props.title}
                        </div>
                    </button>
                </div>
                <div className={`${!deployed && "hidden"}`}>
                    {props.children}
                </div>
            </div>
        </div>
        </>
    );
}

export default Item;
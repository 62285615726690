
import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const getImage = (imageId, size) => {

    let resize = "";

    if(size !== undefined)
        resize = "&size="+size;

    const token = localStorage.getItem("accessToken");
    return SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/image?token="+token+"&imageId="+imageId+resize;
};

export const getHistory = (page) => {
    const token = localStorage.getItem("accessToken");
    const obj = {
        token: token,
        page: page
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/history",
                        {
                            params: obj
                        }
                    );
};

export const getDetails = (imageId) => {
    
        const token = localStorage.getItem("accessToken");
        const obj = {
            token: token,
            imageId: imageId};
    
        return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/data/image",
                            {
                                params: obj
                            }
                        );
    };
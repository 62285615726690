import React from 'react';

const Logo = () =>
 {
    return(
        <>
            <a href="/">
                <div className="font-bricolage text-white text-2xl font-bold">
                    Canvique
                </div>
            </a>
        </>
    )
}


export default Logo;

import React from "react";


const CheckedIcon = () => {
    return(
        <svg
        className="h-5 w-5 text-accent-standard"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        >
        <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
        />
        </svg>
    )
}


const Option = (props) => {

    return(
        <>
            <div onClick={() => props.setPriceIndex(props.index)}
            >
                <label
                    className={`flex cursor-pointer items-center justify-between 
                                rounded-lg border border-secondary p-4 
                                text-sm font-medium shadow-sm hover:border-black
                                ${props.priceIndex === props.index && "border-accent-standard"} 
                                ${props.priceIndex === props.index && "ring-1"}
                                ${props.priceIndex === props.index && "ring-accent-standard"}`}
                >
                    <div className="flex items-center gap-2">
                        {
                            props.priceIndex === props.index && (
                                <CheckedIcon />
                            )
                        }
                        <p className="text-black">{props.price.width} x {props.price.height}cm</p>
                    </div>

                    <p className="text-black">{props.price.price.toFixed(2)} €</p>
                </label>
            </div>

        </>
    )
}

export default Option;

import axios from "axios";
import { SERVER_ADDRESS, SERVER_PORT } from "./constants";

export const googleLogin = (token) => {
    const obj = JSON.stringify({
        token: token});

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/google/auth",
                                    obj,
                                    {
                                        headers: { 'Content-Type': 'application/json' },
                                        withCredentials: true
                                    });
}
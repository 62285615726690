import React, {useState, useEffect, useContext, useRef} from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Button from '../../common/button';
import Loader from '../../loader/loader';
import Alert from '../../common/alert';
import Dropdown from '../../common/dropdown';
import {Input} from '../../common/input';
import { RemoveItemFromBasket } from '../../../api/basket';
import { get_shipping_price } from "../../../api/sizes";
import { changeBasketAddress } from "../../../api/basket";
import { getCountries } from "../../../api/address";
import BasketContext from '../../../context/BasketProvider';


const CheckoutItem = (props) => {

    const navigate = useNavigate();

    const handleRemoveFromBasket = (itemId) => {
        RemoveItemFromBasket(itemId)
            .then((res)=>{
                props.fetchBasket();
            })
            .catch((err)=>{
                console.log(err);
            })
    }

    return(
        <>
            <div className="group relative flex flex-row w-full gap-2 p-2">
                <div className="bg-black cursor-pointer hover:brightness-50 min-w-[150px]"
                        onClick={()=>navigate("/canvas/"+props.imageId)}
                >
                    <img alt="Couldn't load this image :("
                        src={props.url}
                        className="w-[150px] h-[150px] object-contain"
                        />
                </div>
                <div className="flex flex-col justify-between text-black py-2">
                    <nav className="line-clamp-3">
                        {
                            props.prompt
                        }
                    </nav>
                    <div>
                        <nav className="text-sm font-light">
                            {
                                props.width + " x " + props.height + "cm"
                            }
                        </nav>
                        <nav className="text-sm font-light">
                            {
                                props.price + " €"
                            }
                        </nav>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="absolute top-2 right-2 text-delete-standard hover:text-delete-active cursor-pointer"
                        onClick={()=>handleRemoveFromBasket(props.itemId)}
                >
                    <FaRegTrashAlt className="w-[15px] h-[15px]"/>
                </div>
            </div>
        </>
    );

};

const Checkout = (props) => {

    const {basketContent, basketAddress, basketId, loaded, basketDropdownOpen, setBasketDropdownOpen, fetchBasket} = useContext(BasketContext);
    const navigate = useNavigate();

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertPositive, setAlertPositive] = useState(false);

    const [user, setUser] = useState();

    const [shipping_price, setShipping_price] = useState(0);

    const alertRef = useRef();

    const [fullName, setFullName] = useState('');
    const [fullNameValid, setFullNameValid] = useState(false);
    const [fullNameFocus, setFullNameFocus] = useState(false);

    const [address_1, setAddress_1] = useState('');
    const [address_1Valid, setAddress_1Valid] = useState(false);
    const [address_1Focus, setAddress_1Focus] = useState(false);

    const [address_2, setAddress_2] = useState('');
    const [address_2Valid, setAddress_2Valid] = useState(false);
    const [address_2Focus, setAddress_2Focus] = useState(false);

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');

    const [city, setCity] = useState('');
    const [cityValid, setCityValid] = useState(false);
    const [cityFocus, setCityFocus] = useState(false);

    const [postalCode, setPostalCode] = useState('');
    const [postalCodeValid, setPostalCodeValid] = useState(false);
    const [postalCodeFocus, setPostalCodeFocus] = useState(false);

    const [state, setState] = useState('');
    const [stateValid, setStateValid] = useState(false);
    const [stateFocus, setStateFocus] = useState(false);

    const [contact_phone, setContact_phone] = useState('');
    const [contact_phoneValid, setContact_phoneValid] = useState(false);
    const [contact_phoneFocus, setContact_phoneFocus] = useState(false);

    useEffect(() => {
        setFullNameValid(fullName.length > 0);
    }, [fullName]);

    useEffect(() => {
        setAddress_1Valid(address_1.length > 0);
    }, [address_1]);

    useEffect(() => {
        setCityValid(city.length > 0);
    }, [city]);

    useEffect(() => {
        setPostalCodeValid(postalCode.length > 0);
    }, [postalCode]);

    useEffect(() => {
        setStateValid(state.length > 0);
    }, [state]);

    useEffect(() => {
        if(contact_phone === undefined || contact_phone === null){
            setContact_phone("");
        }
        setContact_phoneValid(contact_phone.length > 0);
    }, [contact_phone]);

    const handleSubmit = () => {

        ReactGA.event({
            category: "Button",
            action: "Checkout",
            label: "Checkout button clicked"
        });

        ReactPixel.track('InitiateCheckout',
                         {
                            value: parseFloat(basketContent.reduce((acc, item) => acc + item.price, 0)) + parseFloat(shipping_price),
                            currency: 'EUR'
                         }
        );

        TiktokPixel.track('InitiateCheckout',
                        {
                            value: parseFloat(basketContent.reduce((acc, item) => acc + item.price, 0)) + parseFloat(shipping_price),
                            currency: 'EUR'
                        }
        );

        changeBasketAddress(
            fullName,
            address_1,
            address_2,
            country,
            postalCode,
            city,
            contact_phone,
            state,
            basketId
        ).then((res)=>{
            navigate("/payment");
        }).catch((err)=>{
            console.log(err);
        })
    }

    useEffect(() => {
        setBasketDropdownOpen(false);
        getCountries().then((res) => {
            setCountries(res.data.countries.map((data)=>{
                return {value: data.id, label: data.country}
            }));
        }).catch((err) => {
            console.log(err);
        });
        //updateAddress();
    }, [])

    useEffect(()=>{
        if(loaded){
            setFullName(basketAddress.full_name);
            setAddress_1(basketAddress.address_line_1);
            setAddress_2(basketAddress.address_line_2);
            setCountry(basketAddress.country_id);
            setPostalCode(basketAddress.postal_code);
            setCity(basketAddress.city);
            setContact_phone(basketAddress.contact_phone);
            setState(basketAddress.state_province);
        }
    }, [loaded])


    useEffect(() => {
        if(country){
            get_shipping_price(basketId, country).then((res) => {
                setShipping_price(res.data.shipping_price.toFixed(2));
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [country]);

    return(
        <>

            <div className="w-full bg-white text-black text-left">
                <div className="flex flex-col max-w-xl p-4 py-2 mx-auto">
                    <h1 className="text-3xl font-bold font-bricolage p-4">Checkout</h1>
                    <div className="flex flex-col gap-2 overflow-y-scroll min-h-[300px] max-h-[500px] text-left">
                    {
                        loaded ? (
                            basketContent.map(( item, index ) => {
                                return(
                                    <CheckoutItem
                                        key={index}
                                        itemId={item.item_id}
                                        imageId={item.image_id}
                                        width={item.width}
                                        height={item.height}
                                        price={item.price}
                                        prompt={item.prompt}
                                        fetchBasket={fetchBasket}
                                        url={item.snapshot_url}
                                        />
                                        );
                                    })
                        ) : (
                            <Loader />
                            )

                        }
                    </div>
                    <hr className="my-4"/>
                    <div className="bg-white rounded-xl p-4 m-1">
                        <Alert
                            ref={alertRef}
                            positive={alertPositive}
                            title={alertTitle}
                            text={alertMessage}
                            visible={alertVisible}
                            setVisible={setAlertVisible}
                        />
                        <h2 className="text-2xl font-bold font-bricolage p-2">Shipping Address</h2>
                        <form onSubmit={()=>handleSubmit()} className="mt-8 grid grid-cols-3 gap-6 text-left">
                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                Full Name
                                </label>
                                <Input
                                    type="text"
                                    id="fullname"
                                    name="fullname"
                                    onChange={(e) => {setFullName(e.currentTarget.value)}}
                                    className="border-solid border-[1px] border-black"
                                    required
                                    value={fullName}
                                    onFocus={() => setFullNameFocus(true)}
                                    onBlur={() => setFullNameFocus(false)}
                                />
                                {!fullNameValid && fullNameFocus &&
                                    <span className="mt-2 text-sm text-rose-500">
                                        Enter a full name<br />
                                    </span>
                                }
                            </div>

                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                Phone number
                                </label>
                                {/* <Input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    onChange={(e) => {setContact_phone(e.currentTarget.value)}}
                                    className="border-solid border-[1px] border-black"
                                    required
                                    value={contact_phone}
                                    onFocus={() => setContact_phoneFocus(true)}
                                    onBlur={() => setContact_phoneFocus(false)}
                                /> */}
                                <PhoneInput
                                    value={contact_phone}
                                    onChange={setContact_phone}
                                    inputStyle={{width: '100%'}}
                                    country={'fr'}
                                    containerClass="border-solid border-[1px] rounded-md border-black"
                                    inputProps={{
                                        required: true,
                                        onFocus: () => setContact_phoneFocus(true),
                                        onBlur: () => setContact_phoneFocus(false)
                                    }}
                                />

                                {!contact_phoneValid && contact_phoneFocus &&
                                    <span className="mt-2 text-sm text-rose-500">
                                        Enter a phone number to be joined during delivery<br />
                                    </span>
                                }
                            </div>

                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                Address line 1, Number, Street
                                </label>
                                <Input
                                    type="text"
                                    id="address_1"
                                    name="address_1"
                                    onChange={(e) => {setAddress_1(e.currentTarget.value)}}
                                    className="border-solid border-[1px] border-black"
                                    required
                                    value={address_1}
                                    onFocus={() => setAddress_1Focus(true)}
                                    onBlur={() => setAddress_1Focus(false)}
                                />
                                {!address_1Valid && address_1Focus &&
                                    <span className="mt-2 text-sm text-rose-500">
                                        Enter an address. For instance <i>10 Street John Doe</i><br />
                                    </span>
                                }
                            </div>

                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                Address line 2, Optional, Building, Appartment...
                                </label>
                                <Input
                                    type="text"
                                    id="address_2"
                                    name="address_2"
                                    onChange={(e) => {setAddress_2(e.currentTarget.value)}}
                                    className="border-solid border-[1px] border-black"
                                    value={address_2}
                                />
                            </div>

                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                Country
                                </label>
                                <Dropdown
                                    options={countries}
                                    onChange={(e) => {setCountry(e.currentTarget.value)}}
                                    value={country}
                                />
                            </div>

                            <div className="col-span-3 flex flex-row w-full justify-between">
                                <div className="w-[45%]">
                                    <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                        City
                                    </label>
                                    <Input
                                        type="text"
                                        id="city"
                                        name="city"
                                        onChange={(e) => {setCity(e.currentTarget.value)}}
                                        className="border-solid border-[1px] border-black"
                                        required
                                        value={city}
                                        onFocus={() => setCityFocus(true)}
                                        onBlur={() => setCityFocus(false)}
                                    />
                                    {!cityValid && cityFocus &&
                                        <span className="mt-2 text-sm text-rose-500">
                                            Enter a city<br />
                                        </span>
                                    }
                                </div>
                                <div className="w-[45%]">
                                        <label
                                        htmlFor="Full Name"
                                        className="block text-sm font-medium text-black"
                                        >
                                            Postal Code
                                        </label>
                                        <Input
                                            type="text"
                                            id="postal_code"
                                            name="postal_code"
                                            onChange={(e) => {setPostalCode(e.currentTarget.value)}}
                                            className="border-solid border-[1px] border-black"
                                            required
                                            value={postalCode}
                                            onFocus={() => setPostalCodeFocus(true)}
                                            onBlur={() => setPostalCodeFocus(false)}
                                        />
                                        {!postalCodeValid && postalCodeFocus &&
                                            <span className="mt-2 text-sm text-rose-500">
                                                Enter a Postal Code<br />
                                            </span>
                                        }
                                    </div>
                                </div>

                            <div className="col-span-3">
                                <label
                                    htmlFor="Full Name"
                                    className="block text-sm font-medium text-black"
                                    >
                                State / Province
                                </label>
                                <Input
                                    type="text"
                                    id="state"
                                    name="state"
                                    onChange={(e) => {setState(e.currentTarget.value)}}
                                    className="border-solid border-[1px] border-black"
                                    required
                                    value={state}
                                    onFocus={() => setStateFocus(true)}
                                    onBlur={() => setStateFocus(false)}
                                />
                                {!stateValid && stateFocus &&
                                    <span className="mt-2 text-sm text-rose-500">
                                        Enter a state or a province<br />
                                    </span>
                                }
                            </div>
                        </form>
                    </div>
                    <div className="flex flex-row bg-primary rounded-xl p-4 m-1 justify-center">
                        <div className="flex w-[30%] justify-center">
                            <Button onClick={()=>{handleSubmit()}}>
                                Checkout
                            </Button>
                        </div>
                        <div className="flex flex-col text-right w-[70%]">
                            <span className="text-white">
                                Subtotal : <span className="text-accent-standard">{basketContent.reduce((acc, item) => acc + item.price, 0).toFixed(2)} €</span>
                            </span>
                            <span className="text-white">
                                Shipping : <span className="text-accent-standard">{parseFloat(shipping_price).toFixed(2)} €</span>
                            </span>
                            <span className="text-white text-lg">
                                Total : <span className="text-accent-standard text-md">{(parseFloat(basketContent.reduce((acc, item) => acc + item.price, 0)) + parseFloat(shipping_price)).toFixed(2)} €</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Checkout;
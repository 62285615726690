import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const postMessage = (name,
                            email,
                            message) => {

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/contact/message",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
}


export const postBusinessInquiries = (name,
                                        email,
                                        message) => {
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/contact/business",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                            });
}


export const postSupport = (order_id, message) => {
    
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('order_id', order_id);
    formData.append('message', message);
    formData.append('token', token);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/contact/support",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                            });
}

export const postNewsletter = (email) => {
        
        const formData = new FormData();
        formData.append('email', email);
    
        return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/contact/cta",
                            formData,
                            {
                                headers: { 'Content-Type': 'multipart/form-data' },
                                withCredentials: true,
                                crossDomain: true
                                });
    }

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Button from "../common/button";
import { Input } from "../common/input";

import { BsEmojiSunglasses } from "react-icons/bs";
import { FaCheckDouble } from "react-icons/fa";

import { postResetPassword } from "../../api/user";


const PWD_REGEX = /^.{8,28}$/;

const PasswordReset = (props) => {

        const [done, setDone] = useState(false);

        const [password1, setPassword1] = useState("");
        const [password2, setPassword2] = useState("");

        const [validPwd1, setValidPwd1] = useState(false);
        const [pwdFocus1, setPwdFocus1] = useState(false);

        const [validPwd2, setValidPwd2] = useState(false);
        const [pwdFocus2, setPwdFocus2] = useState(false);

        const { token } = useParams();
        const navigate = useNavigate();

        useEffect(()=>{
            setValidPwd1(PWD_REGEX.test(password1) && password1.length >= 8 && password1.length <= 24);
        }, [password1]);

        useEffect(()=>{
            setValidPwd2(password1 === password2);
        }, [password1, password2]);

        useEffect(()=>{
            if (token === undefined || token === null || token === "") {
                navigate("/");
            }
        }, []);

        const handleSubmit = async (e) => {
            e.preventDefault();
            postResetPassword(password1, password2, token).then((res) => {
                setDone(true);
            }).catch((err) => {
                navigate("/");
            });
        }

        return(
            <>
                {
                    !done ? (
                        <div className="flex justify-center items-center h-[80vh] w-full bg-white">
                            <div className="flex flex-col gap-2 max-w-[360px] p-4">

                                <BsEmojiSunglasses className="text-6xl text-primary mx-auto"/>

                                <h2 className="text-2xl font-bricolage text-black font-bold m-4">
                                    Reset your password!
                                </h2>

                                <div className="w-full">
                                    <p>
                                        Hello again!
                                    </p>
                                    <p>
                                        You will now choose a new password. Please, try to remember it this time ;)
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit} className="flex flex-col gap-2">

                                    <div className="col-span-3">
                                        <label
                                        htmlFor="Password"
                                        className="block text-sm font-medium text-white"
                                        >
                                        Choose a new password
                                        </label>

                                        <Input
                                            className={!validPwd1 && password1 ? "border-2 border-rose-500" : ""}
                                            placeholder="Type your new password"
                                            onChange={(e) => setPassword1(e.target.value)}
                                            value={password1}
                                            aria-invalid={validPwd1 ? "false" : "true"}
                                            type="password"
                                            onFocus={() => setPwdFocus1(true)}
                                            onBlur={() => setPwdFocus1(false)}
                                            />
                                        {!validPwd1 && pwdFocus1 &&
                                            <span className="mt-2 text-sm text-rose-500">
                                                8 to 28 characters.<br />
                                            </span>
                                        }
                                    </div>
                                    <div className="col-span-3">
                                        <label
                                        htmlFor="Password"
                                        className="block text-sm font-medium text-white"
                                        >
                                        Choose a new password
                                        </label>

                                        <Input
                                            className={!validPwd2 && password2 ? "border-2 border-rose-500" : ""}
                                            placeholder="Retype it"
                                            onChange={(e) => setPassword2(e.target.value)}
                                            value={password2}
                                            aria-invalid={validPwd2 ? "false" : "true"}
                                            type="password"
                                            onFocus={() => setPwdFocus2(true)}
                                            onBlur={() => setPwdFocus2(false)}
                                            />
                                        {!validPwd2 && pwdFocus2 &&
                                            <span className="mt-2 text-sm text-rose-500">
                                                8 to 28 characters.<br />
                                            </span>
                                        }
                                    </div>
                                    <Button>
                                        Reset password
                                    </Button>
                                </form>

                                <div className="w-full font-light">
                                    <p>
                                        Something is wrong ? &nbsp;
                                        <a href="/contact/message" className="underline text-accent-standard hover:text-accent-active font-bold">contact us</a>.
                                    </p>
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-[80vh] w-full bg-white">
                            <div className="flex flex-col gap-6 max-w-[360px] p-4">

                            <FaCheckDouble className="text-6xl text-accent-standard mx-auto"/>

                            <h2 className="text-2xl font-bricolage text-black font-bold m-4">
                                Password changed!
                            </h2>

                            <p>
                                Your password has been changed. You can now login with your new password.
                            </p>
                            </div>
                    </div>
                    )
                }
            </>
        );
}


export default PasswordReset;
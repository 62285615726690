
import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const getBasket = () => {
    const token = localStorage.getItem("accessToken");
    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/basket?token="+token,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        crossDomain: true
                    });
}

export const getOrders = () => {
    const token = localStorage.getItem("accessToken");
    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/orders?token="+token,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        crossDomain: true
                    });
}

export const getDeliveredOrders = () => {
    const token = localStorage.getItem("accessToken");
    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/delivered?token="+token,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        crossDomain: true
                    });
}

export const createOrder = (basketId) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('basket_id', basketId);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/order/create",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });

}

export const AddItemToBasket = (imageId, width, height, price, size_id) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('image_id', imageId);
    formData.append('width', width);
    formData.append('height', height);
    formData.append('price', price);
    formData.append('size_id', size_id);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/basket/add",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
}

export const RemoveItemFromBasket = (itemId) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('item_id', itemId);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/basket/remove",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
}

export const changeBasketAddress = (
    full_name,
    address_line_1,
    address_line_2,
    country_id,
    postal_code,
    city,
    contact_phone,
    state_province,
    basket_id
) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('full_name', full_name);
    formData.append('address_line_1', address_line_1);
    formData.append('address_line_2', address_line_2);
    formData.append('country_id', country_id);
    formData.append('postal_code', postal_code);
    formData.append('city', city);
    formData.append('contact_phone', contact_phone);
    formData.append('state_province', state_province);
    formData.append('basket_id', basket_id);

    return axios.put(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/basket/address",
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
            crossDomain: true
        });
}


export const get_order_session_id = (session_id) => {

    const token = localStorage.getItem("accessToken");
    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/order/session_id?session_id="+session_id+"&token="+token,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        crossDomain: true
                    });
}


import React, { useEffect, useState } from 'react';
import TimeSeriesLineChart from './adminUserChart';
import AdminOrder from './adminOrder';

const AdminPage = () => {

    return (
        <div className='p-2'>
            <TimeSeriesLineChart />
            <AdminOrder />
        </div>
    );
}

export default AdminPage;
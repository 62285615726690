
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IoOpenOutline } from "react-icons/io5";


const Snapshot = (props) => {

    const openModal = () => {
        props.setModalURL(props.canvas_url);
        props.setModalID(props.id);
        props.setOpenModal(true);
    }

    return(
        <>
            { props.snapshot_url ?
                (
                    <>
                        <div className="group relative flex aspect-square shrink-0 grow flex-col justify-center
                                        overflow-hidden rounded bg-black sm:min-h-0 sm:shrink sm:basis-0
                                        cursor-pointer hover:shadow-2xl"
                             onClick={() => openModal()}
                        >

                            <LazyLoadImage
                                alt="Couldn't load this image :("
                                src={props.snapshot_url}
                                className="h-full w-full grow basis-0 object-contain sm:h-[999999px] sm:min-h-0 sm:w-[999999px] opacity-100 group-hover:opacity-40"
                            />
                            <IoOpenOutline className="absolute top-0 right-0 w-6 h-6 m-2 text-white opacity-0 group-hover:opacity-100"/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex justify-center items-center text-center text-sm w-[250px] h-[250px] bg-primary text-white rounded-lg">
                            Click on <span className="p-2 m-1 bg-accent-standard text-black rounded-md">Generate</span> to start creating
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Snapshot;

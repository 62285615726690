
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthProvider';
import Login from '../login/login';
import Button from '../common/button';
import banner from "../../assets/banner.webp";


const Banner = () => {

    const [openLogin, setOpenLogin] = useState(false);
    const {auth} = useContext(AuthContext);
    const navigate = useNavigate();

    return(
        <>
            <section className="relative w-full h-full" id="banner">
                <img className="w-full h-[100vh] object-cover object-center"
                     src={banner}
                     alt="Canvique AI banner" />

                <div className="absolute inset-0 bg-gradient-to-t from-black">
                </div>

                <div className="absolute text-left bottom-[75px] sm:w-[50%] w-[80%] m-[20px] sm:m-0 sm:left-[30%] left-[10%]">
                    <h1 className="text-accent-standard text-5xl font-black font-bricolage">
                        Become an artist
                    </h1>
                    <h3 className="text-lg font-bricolage text-white font-medium">
                        Turn your pictures and your ideas into art and get your creations delivered at your doorstep.
                    </h3>
                    <h4 className="font-bricolage text-white">
                        Poster, canvas, metal plate, you name it.
                    </h4>
                    <p className="mt-[30px]">
                        {
                            auth ? (
                                <Button onClick={()=>navigate("/create")}>
                                    Start creating
                                </Button>
                            ) : (
                                <Button onClick={()=>setOpenLogin(true)}>
                                    Login to get started
                                </Button>
                            )
                        }
                    </p>
                </div>
            </section>
            {
                openLogin &&
                <Login
                    setOpen={setOpenLogin}
                />
            }
        </>
    );
}

export default Banner;
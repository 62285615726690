
import React from 'react';
import { BsShieldFillCheck } from "react-icons/bs";

import { FaCcVisa } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcAmex } from "react-icons/fa";
import { FaApplePay } from "react-icons/fa";
import { FaGooglePay } from "react-icons/fa";


const PaymentLandPage = () => {
    return (
        <section className="w-full h-full bg-black justify-center items-center">
            <div className="flex flex-col md:flex-row bg-black py-12 px-8 mx-auto rounded-md items-center">
                <div className="rounded-full h-[120px] w-[120px] p-4 bg-primary flex justify-center items-center">
                    <BsShieldFillCheck className="text-accent-standard w-[100px] h-[100px]" />
                </div>
                <div className="flex flex-col justify-center p-4">
                    <h1 className="font-bold flex text-4xl text-accent-standard">
                        <p className="font-black font-bricolage">
                            Secured Payment
                        </p>
                    </h1>
                    <div className="text-white">
                        <p>
                            Payments on this website are secured by Stripe. One of the most secure payment platforms in the world.
                        </p>
                        <p>
                            Learn more about Stripe <a href="https://stripe.com" className="text-accent-standard" target="_blank">here</a>.
                        </p>
                        <p>
                            Furthermore, we do not store any of your payment information on our servers. To learn more about our privacy policy, click <a href="/legal/privacy-policy" className="text-accent-standard">here</a>.
                        </p>
                    </div>
                    <div className="flex flex-col p-4">
                        <div className="flex flex-row gap-2">
                            <FaCcVisa className="text-[#635bff] w-[50px] h-[50px]"/>
                            <FaCcMastercard className="text-[#635bff] w-[50px] h-[50px]"/>
                            <FaCcAmex className="text-[#635bff] w-[50px] h-[50px]"/>
                            <FaApplePay className="text-[#635bff] w-[50px] h-[50px]"/>
                            <FaGooglePay className="text-[#635bff] w-[50px] h-[50px]"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PaymentLandPage;

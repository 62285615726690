

import React, { useEffect, useState, useContext } from "react";
import ChangePassword from "./changePassword";

import AuthContext from "../../context/AuthProvider";
import Loader from "../loader/loader";

import { getAccount } from "../../api/user";
import Button from "../common/button";
import ChangeAddress from "./changeAddress";


const Account = (props) => {

    const {auth} = useContext(AuthContext);
    const [user, setUser] = useState();
    const [loaded, setLoaded] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [openChangeAddress, setOpenChangeAddress] = useState(false);

    const updateAddress = () => {
        getAccount().then((res)=>{
            setLoaded(true);
            setUser(res.data);
        }).catch((err)=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        if(auth){
            updateAddress();
        }
    }, [auth]);

    return(
        <>
            <div className="flex flex-col justify-center text-black h-[800px] bg-white w-full text-left">
                {
                    loaded ? (
                        <>
                            <div className="flex flex-col justify-center md:w-[50%] w-full m-auto gap-2 p-4 md:p-0">
                                <div className="flex flex-row justify-between">
                                    <h3 className="text-xl font-black">
                                        Account
                                    </h3>
                                    <div></div>
                                </div>
                                <hr className="" />
                                <div className="flex flex-col gap-1">
                                    <div>
                                        Name : <span className="font-semibold"> {user.name} </span>
                                    </div>
                                    <div>
                                        Email : <span className="font-semibold"> {user.email} </span>
                                    </div>
                                    {
                                        !user.google_auth && 
                                        <div className="my-2">
                                            <Button onClick={()=>setOpenChangePassword(true)}>
                                                Change password
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <div className="flex flex-row justify-between mt-4">
                                    <h3 className="text-xl font-black">
                                        Shipping Details
                                    </h3>
                                    <div className="color-black hover:underline hover:decoration-solid decoration-black cursor-pointer"
                                         onClick={()=>setOpenChangeAddress(true)}
                                    >
                                        Modify
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="flex flex-col gap-1">
                                    <div>
                                        Full Name : <span className="font-semibold"> {user.full_name} </span>
                                    </div>
                                    <div>
                                        Address 1 : <span className="font-semibold"> {user.address_line_1} </span>
                                    </div>
                                    <div>
                                        Address 2 : <span className="font-semibold"> {user.address_line_2} </span>
                                    </div>
                                    <div>
                                        Country : <span className="font-semibold"> {user.country} </span>
                                    </div>
                                    <div>
                                        City : <span className="font-semibold"> {user.city} </span>
                                    </div>
                                    <div>
                                        Postal Code : <span className="font-semibold"> {user.postal_code} </span>
                                    </div>
                                    <div>
                                        State/province : <span className="font-semibold"> {user.state_province} </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )
                }
            </div>
            {
                openChangePassword && 
                    <ChangePassword
                        setOpen={setOpenChangePassword}
                        isOpen={openChangePassword}
                    />
            }
            {
                openChangeAddress && 
                    <ChangeAddress
                        setOpen={setOpenChangeAddress}
                        isOpen={openChangeAddress}
                        update={updateAddress}
                    />
            }
        </>
    )
}

export default Account;
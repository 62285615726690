
import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const createCheckoutSession = (basket_id) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('basket_id', basket_id);

    return axios.post(`${SERVER_ADDRESS}:${SERVER_PORT}/api/stripe/checkout_session`,
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
            crossDomain: true
        });
}
import React from "react";
import Snapshot from "./snapshot/snapshot";

import Loader from "../../loader/loader";

const History = (props) => {

    return(
        <>
        {
            <div className="h-full w-full pb-16">
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    {
                        props.history.map((o, index) => {
                            return(
                                <Snapshot
                                    key={index}
                                    snapshot_url={o.snapshot_url}
                                    canvas_url={o.canvas_url}
                                    id={o.image_id}
                                    scroll={index === props.history.length - 1}
                                    setModalID={props.setModalID}
                                    setModalURL={props.setModalURL}
                                    setOpenModal={props.setOpenModal}
                                    setImageToUse={props.setImageToUse}
                                    setStep={props.setStep}
                                    />
                            )
                        })
                    }
                </div>
                {
                    props.loading && (
                        <div className="w-full h-full flex justify-center items-center p-16">
                            <Loader />
                        </div>
                    )
                }
            </div>
        }
        </>
    );

}

export default History;
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { get_user_count } from '../../api/admin';

const TimeSeriesLineChart = (props) => {

    const [nbUsers, setnbUsers] = useState([]);
    const [days, setDays] = useState([]);


    useEffect(() => {
        get_user_count().then((response) => {
            setnbUsers(response.data.user_per_day);
            setDays(response.data.day_list);
        });
    }, []);

  const data = days.map((day, index) => ({
    day: day,
    nb_users: nbUsers[index],
  }));

  return (
    <div>
      <h2 className="font-bold text-2xl font-bricolage">User Registration Time Series</h2>
       <div className="w-full h-full overflow-x-auto">
            <LineChart
                width={1200} // Specify the width of the chart
                height={400}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="nb_users" stroke="#8884d8" />
            </LineChart>
        </div>
    </div>
  );
};

export default TimeSeriesLineChart;

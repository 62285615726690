
import React, { useState, useEffect, useRef } from 'react';
import Alert from '../common/alert';
import Button from '../common/button';

import { Input } from "../common/input";
import { getCountries } from '../../api/address';
import { changeAddress } from '../../api/user';
import Dropdown from '../common/dropdown';

const ChangeAddress = (props) => {
    
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertPositive, setAlertPositive] = useState(false);

    const alertRef = useRef();
    
    const [fullName, setFullName] = useState('');
    const [fullNameValid, setFullNameValid] = useState(false);
    const [fullNameFocus, setFullNameFocus] = useState(false);

    const [address_1, setAddress_1] = useState('');
    const [address_1Valid, setAddress_1Valid] = useState(false);
    const [address_1Focus, setAddress_1Focus] = useState(false);

    const [address_2, setAddress_2] = useState('');
    const [address_2Valid, setAddress_2Valid] = useState(false);
    const [address_2Focus, setAddress_2Focus] = useState(false);

    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');

    const [city, setCity] = useState('');
    const [cityValid, setCityValid] = useState(false);
    const [cityFocus, setCityFocus] = useState(false);

    const [postalCode, setPostalCode] = useState('');
    const [postalCodeValid, setPostalCodeValid] = useState(false);
    const [postalCodeFocus, setPostalCodeFocus] = useState(false);

    const [state, setState] = useState('');
    const [stateValid, setStateValid] = useState(false);
    const [stateFocus, setStateFocus] = useState(false);

    const [contact_phone, setContact_phone] = useState('');
    const [contact_phoneValid, setContact_phoneValid] = useState(false);
    const [contact_phoneFocus, setContact_phoneFocus] = useState(false);

    useEffect(() => {
        setFullNameValid(fullName.length > 0);
    }, [fullName]);

    useEffect(() => {
        setAddress_1Valid(address_1.length > 0);
    }, [address_1]);

    useEffect(() => {
        setCityValid(city.length > 0);
    }, [city]);

    useEffect(() => {
        setPostalCodeValid(postalCode.length > 0);
    }, [postalCode]);

    useEffect(() => {
        setStateValid(state.length > 0);
    }, [state]);

    useEffect(() => {
        setContact_phoneValid(contact_phone.length > 0);
    }, [contact_phone]);

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res.data.countries.map((data)=>{
                return {value: data.id, label: data.country}
            }));
        }).catch((err) => {
            console.log(err);
        });
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        changeAddress(
            fullName,
            address_1,
            address_2,
            country,
            postalCode,
            city,
            contact_phone,
            state,
        ).then((res)=>{
            props.update();
            props.setOpen(false);
        }).catch((err)=>{
            console.log(err);
            setAlertTitle("Error");
            setAlertMessage(err.response.data.message);
            setAlertPositive(false);
            setAlertVisible(true);
        })
    }

    return(
        <>
            <div className="fixed inset-0 z-100 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 py-8 mx-auto bg-primary rounded-md shadow-lg">
                            <div className="mt-3">
                                <div className="mt-2 text-center">
                                    <div className="flex flex-cols">
                                        <h2
                                        className="text-2xl font-bold text-white sm:text-3xl md:text-4xl font-bricolage"
                                        >
                                            Change Shipping Address
                                        </h2>
                                    </div>
                                    <Alert
                                        ref={alertRef}
                                        positive={alertPositive}
                                        title={alertTitle}
                                        text={alertMessage}
                                        visible={alertVisible}
                                        setVisible={setAlertVisible}
                                    />
                                    <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-3 gap-6 text-left">
                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Full Name
                                            </label>
                                            <Input
                                                type="text"
                                                id="fullname"
                                                name="fullname"
                                                onChange={(e) => {setFullName(e.currentTarget.value)}}
                                                required
                                                value={fullName}
                                                onFocus={() => setFullNameFocus(true)}
                                                onBlur={() => setFullNameFocus(false)}
                                            />
                                            {!fullNameValid && fullNameFocus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    Enter a full name<br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Phone number
                                            </label>
                                            <Input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                onChange={(e) => {setContact_phone(e.currentTarget.value)}}
                                                required
                                                value={contact_phone}
                                                onFocus={() => setContact_phoneFocus(true)}
                                                onBlur={() => setContact_phoneFocus(false)}
                                            />
                                            {!contact_phoneValid && contact_phoneFocus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    Enter a phone number to be joined during delivery<br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Address line 1, Number, Street
                                            </label>
                                            <Input
                                                type="text"
                                                id="address_1"
                                                name="address_1"
                                                onChange={(e) => {setAddress_1(e.currentTarget.value)}}
                                                required
                                                value={address_1}
                                                onFocus={() => setAddress_1Focus(true)}
                                                onBlur={() => setAddress_1Focus(false)}
                                            />
                                            {!address_1Valid && address_1Focus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    Enter an address. For instance <i>10 Street John Doe</i><br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Address line 2, Optional, Building, Appartment... 
                                            </label>
                                            <Input
                                                type="text"
                                                id="address_2"
                                                name="address_2"
                                                onChange={(e) => {setAddress_2(e.currentTarget.value)}}
                                                value={address_2}
                                            />
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Country
                                            </label>
                                            <Dropdown
                                                options={countries}
                                                onChange={(e) => {setCountry(e.currentTarget.value)}}
                                                value={country}
                                            />
                                        </div>

                                        <div className="col-span-3 flex flex-row w-full justify-between">
                                            <div className="w-[45%]">
                                                <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                                    City
                                                </label>
                                                <Input
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    onChange={(e) => {setCity(e.currentTarget.value)}}
                                                    required
                                                    value={city}
                                                    onFocus={() => setCityFocus(true)}
                                                    onBlur={() => setCityFocus(false)}
                                                />
                                                {!cityValid && cityFocus &&
                                                    <span className="mt-2 text-sm text-rose-500">
                                                        Enter a city<br />
                                                    </span>
                                                }  
                                            </div>
                                            <div className="w-[45%]">
                                                    <label
                                                    htmlFor="Full Name"
                                                    className="block text-sm font-medium text-white"
                                                    >
                                                        Postal Code
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        id="postal_code"
                                                        name="postal_code"
                                                        onChange={(e) => {setPostalCode(e.currentTarget.value)}}
                                                        required
                                                        value={postalCode}
                                                        onFocus={() => setPostalCodeFocus(true)}
                                                        onBlur={() => setPostalCodeFocus(false)}
                                                    />
                                                    {!postalCodeValid && postalCodeFocus &&
                                                        <span className="mt-2 text-sm text-rose-500">
                                                            Enter a Postal Code<br />
                                                        </span>
                                                    }  
                                                </div>
                                            </div>
                                        
                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Full Name"
                                                className="block text-sm font-medium text-white"
                                                >
                                            State / Province
                                            </label>
                                            <Input
                                                type="text"
                                                id="state"
                                                name="state"
                                                onChange={(e) => {setState(e.currentTarget.value)}}
                                                required
                                                value={state}
                                                onFocus={() => setStateFocus(true)}
                                                onBlur={() => setStateFocus(false)}
                                            />
                                            {!stateValid && stateFocus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    Enter a state or a province<br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                                            <Button
                                                disabled={
                                                    !fullNameValid ||
                                                    !address_1Valid ||
                                                    !cityValid ||
                                                    !postalCodeValid ||
                                                    !stateValid ||
                                                    !contact_phoneValid
                                                }
                                            >
                                                Change Address
                                            </Button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );

};

export default ChangeAddress;

import React, { useEffect, useState } from 'react';
import Loader from '../../loader/loader';

const GenerateLoader = (props) => {

    const States = [
        "Processing your prompt",
        "Generating your image",
        "Styling your image",
        "Almost there!",
    ]

    const INTERVAL = 1000;
    const FACTOR = 2.5;

    const [loadingState, setLoadingState] = useState(0);

    useEffect(() => {
        if (loadingState < 3) {
            setTimeout(() => {
                setLoadingState(loadingState + 1);
            }, INTERVAL*FACTOR);
        }
    }
    , [loadingState]);

    useEffect(() => {
        setLoadingState(0);
    }, []);

    return(
        <>
            <div className="flex flex-col place-items-center justify center">
                <div className="flex m-4 text-sm justify-center place-items-center h-full">
                    {States[loadingState]}
                </div>
                <div className="h-2 w-[200px] bg-primary">
                    <div className="h-2 bg-accent-standard" style={{ width: (100/States.length)*(loadingState+1)+"%" }}></div>
                </div>
            </div>
        </>
    )

};


export default GenerateLoader;

import {SERVER_ADDRESS, SERVER_PORT} from "./constants";

import axios from "axios";


export const check_admin = () => {

    const token = localStorage.getItem("accessToken");
    const obj = {
        token: token,
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/admin/user",
                        {
                            params: obj
                        }
                    );
}

export const get_user_count = () => {
    const token = localStorage.getItem("accessToken");
    const obj = {
        token: token,
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/admin/user_timeline",
                        {
                            params: obj
                        }
                    );
}

export const get_processing_orders = () => {
    const token = localStorage.getItem("accessToken");
    const obj = {
        token: token,
    };

    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/admin/orders/processing",
                        {
                            params: obj
                        }
                    );
}

export const update_order = (basket_id, status, tracking_url) => {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('tracking_url', tracking_url);
    formData.append('status', status );
    formData.append('basket_id', basket_id);

    return axios.put(SERVER_ADDRESS+":"+SERVER_PORT+"/api/admin/orders",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
}

import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";


const FaqItem = (props) => {
    const [open, setOpen] = useState(false);
    
    return (
        <div className="w-full">
            <div className="flex flex-col">
                <div className="flex flex-row text-xl font-bold justify-between cursor-pointer"
                    onClick={() => setOpen(!open)}
                >
                    <span className={`${open ? "text-accent-standard" : "text-white"}`}>
                        {props.title}
                    </span>
                    <span>
                        <button onClick={() => setOpen(!open)} className="text-white">
                            {open ? <FaMinus /> : <FaPlus />}
                        </button>
                    </span>
                </div>
                <div className={`flex flex-col gap-2 ${open ? "block" : "hidden"} text-white py-4`}>
                    {props.children}
                </div>

            </div>
        </div>
    );
    }

export default FaqItem;

import React from "react";

const PositiveLogo = (props) => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="text-green-600 h-5 w-5"
        >
            <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

const NegativeLogo = (props) => {
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5"
      >
        <path
          fillRule="evenodd"
          d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        />
      </svg>
    );
}

const Alert = React.forwardRef((props, ref) => {

    return(
        <div
            role="alert"
            ref={ref}
            className={`rounded-xl border bg-secondary border-gray-100 p-4 text-white m-5 ${props.visible ? "block" : "hidden"}`}
            >
            <div className="flex items-start gap-4">
                <span>
                    {props.positive ? <PositiveLogo /> : <NegativeLogo />}
                </span>

                <div className="flex-1">
                <strong className="block font-medium text-gray-900 text-white">
                    {props.title}
                </strong>

                <p className="mt-1 text-sm text-gray-700 text-white">
                    {props.text}
                </p>
                </div>

                <button
                className="text-gray-500 transition hover:text-gray-600 text-gray-400 hover:text-gray-500"
                onClick={() => props.setVisible(false)}
                >
                    <span className="sr-only">Dismiss popup</span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
});

export default Alert;
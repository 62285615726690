import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';

import BasketContext from '../../../context/BasketProvider';

import { get_order_session_id } from "../../../api/basket";


const Success = (props) => {

    const {basketContent, basketId, loaded, basketDropdownOpen, setBasketDropdownOpen, fetchBasket} = useContext(BasketContext);
    const {session_id} = useParams();

    useEffect(() => {

        get_order_session_id(session_id).then((response)=>{
            const total_revenue = response.data.items.reduce((acc, item)=>acc+item.price, 0) + response.data.shipping_price;
            ReactGA.event("purchase", {
                value: total_revenue,
                currency: "EUR",
            });
            ReactPixel.track('Purchase', {
                value: total_revenue,
                currency: 'EUR'
            });

            TiktokPixel.track('CompletePayment', {
                value: total_revenue,
                currency: 'EUR'
            });

        }).catch((error)=>{
            console.log(error);
        });

        fetchBasket();
    }
    ,[]);

    return(
        <>
            <div className="flex flex-col w-full h-[600px] justify-center items-center">
                <div className="w-[400px] text-center">
                    <h1 className="text-2xl font-bold">Success</h1>
                    <div className="font-bold">
                        Thank you for your purchase!
                    </div>
                    <div>
                        You should receive an email confirmation shortly!
                    </div>
                </div>
             </div>
        </>
    );
}

export default Success;

import React from 'react';
import Button from '../common/button';

const Shipping = (props) => {

    return(
        <>
            <div className="fixed inset-0 z-100 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-2xl p-4 py-8 mx-auto bg-primary rounded-md shadow-lg">
                            <div className="mt-3">
                                <div className="flex flex-col gap-1 mt-2 text-center text-white">

                                    <h1 className="text-2xl font-bold">
                                        Shipping
                                    </h1>

                                    <div className="mt-4 text-justify">
                                        Thank you for choosing Canvique! <br />
                                        Shipping naturally takes time, but we strive to get your order to you as soon as possible. <br />
                                        Also, shipping times and costs may vary depending on your location and the size of your order. <br />
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Shipping Times
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        Every shipment is handled by FedEX. <br />
                                        Our products are made to order and are usually shipped within 2-3 business days. <br />
                                        Once shipped, you will receive a tracking number via email. <br />
                                        The estimated shipping time is 3-5 business days. <br />
                                        Please note that these times are estimates and may vary depending on your location.
                                        Europe and North-America will be likely to have fast shipping time whereas other locations might take a bit longer but no worries, we will keep you updated! <br />
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Contact
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        If you have any questions or concerns, please contact us at
                                        support@canvique.ai and we will be happy to assist you!
                                    </div>


                                    <div className="w-full justify-center">
                                        <Button onClick={()=>props.setOpen(false)} className="w-[200px]">
                                            Close
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );

};

export default Shipping;
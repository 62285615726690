import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

import Header from './components/header/header';
import RequireAuth from "./components/route/RequireAuth";
import RequireAdmin from "./components/route/RequireAdmin";
import FooterLayout from "./components/layouts/footerLayout";
import PageTitle from "./components/common/pagetitle";

import Create from './components/workspace/create/create';
import Canvas from "./components/workspace/canvas/canvas";
import Checkout from "./components/workspace/checkout/checkout";
import Payment from "./components/workspace/checkout/payment";
import Success from "./components/workspace/checkout/success";
import Support from "./components/contact/support";
import ContactSuccess from "./components/contact/success";
import BusinessInquiries from "./components/contact/business_inquiries";

import Main from './components/main/main';
import Orders from "./components/orders/orders";
import Account from './components/account/Account';
import NotFound from './components/error/notfound';
import Message from "./components/contact/message";

import PageRefundPolicy from './components/policies/pageRefundPolicy';
import PageReturnPolicy from './components/policies/pageReturnPolicy';
import PagePrivacyPolicy from "./components/policies/pagePrivacyPolicy";
import PageConditions from "./components/policies/pageConditions";

import PasswordForgotten from "./components/passwordReset/passwordForgotten";
import PasswordReset from "./components/passwordReset/passwordReset";

import AdminPage from "./components/admin/adminPage";

import { GOOGLE_GAMID, FACEBOOK_PIXEL_ID, TIKTOK_PIXEL_ID } from "./api/constants";

import './App.css';
import './global.css';


const App = () => {

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    ReactGA.initialize(GOOGLE_GAMID);
    ReactPixel.init(FACEBOOK_PIXEL_ID);
    TiktokPixel.init(TIKTOK_PIXEL_ID);

    ReactGA.event({
      hitType: 'pageview',
      page: window.location.pathname,
      category: 'Page view',
      action: 'Page view'
    });

    ReactPixel.pageView();

    TiktokPixel.pageView();
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Header
          isLoginModalOpen={isLoginModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
        />
        <Routes>

          <Route element={<FooterLayout />}>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/password-forgotten" element={
              <>
                <PageTitle title="Canvique - Password forgotten" />
                <PasswordForgotten />
              </>
            } />
            <Route exact path="/password-reset/:token" element={
              <>
                <PageTitle title="Canvique - Password reset" />
                <PasswordReset />
              </>
            } />

            <Route exact path="/contact/business-inquiries" element={
              <>
                <PageTitle title="Canvique - Business inquiries" />
                <BusinessInquiries />
              </>
            } />
            <Route exact path="/contact/success" element={
              <>
                <PageTitle title="Canvique - Success" />
                <ContactSuccess />
              </>
            } />
            <Route exact path="/contact/message" element={
              <>
                <PageTitle title="Canvique - Message" />
                <Message />
              </>
            } />
            <Route exact path="/legal/terms-and-conditions" element={
              <>
                <PageTitle title="Canvique - Terms and conditions" />
                <PageConditions />
              </>
            } />
            <Route exact path="/legal/privacy-policy" element={
              <>
                <PageTitle title="Canvique - Privacy policy" />
                <PagePrivacyPolicy />
              </>
            } />
            <Route exact path="/legal/return-policy" element={
              <>
                <PageTitle title="Canvique - Return policy" />
                <PageReturnPolicy />
              </>
            } />
            <Route exact path="/legal/refund-policy" element={
              <>
                <PageTitle title="Canvique - Refund policy" />
                <PageRefundPolicy />
              </>
            } />
            <Route path='/404' element={
              <>
                <PageTitle title="Canvique - Not found" />
                <NotFound />
              </>
            } />
            <Route path='*' element={<Navigate  to='/404' replace/>}/>
          </Route>

          <Route element={<RequireAuth
                           setIsLoginModalOpen={setIsLoginModalOpen}
                          />}>

              <Route exact path="/create" element={
                <>
                  <PageTitle title="Canvique - Create" />
                  <Create />
                </>
              } />
              <Route exact path="/canvas/:ImageId" element={
                <>
                  <PageTitle title="Canvique - Canvas" />
                  <Canvas />
                </>
              } />
            <Route element={<FooterLayout />}>
              <Route exact path="/account" element={
                <>
                  <PageTitle title="Canvique - Account" />
                  <Account />
                </>
              } />
              <Route exact path="/checkout" element={
                <>
                  <PageTitle title="Canvique - Checkout" />
                  <Checkout />
                </>
              } />
              <Route exact path="/payment" element={
                <>
                  <PageTitle title="Canvique - Payment" />
                  <Payment />
                </>
              } />
              <Route exact path="/success/:session_id" element={
                <>
                  <PageTitle title="Canvique - Success" />
                  <Success />
                </>
              } />
              <Route exact path="/orders" element={
                <>
                  <PageTitle title="Canvique - Orders" />
                  <Orders />
                </>
              } />
              <Route exact path="/contact/support/:orderId?" element={
                <>
                  <PageTitle title="Canvique - Support" />
                  <Support />
                </>
              } />
            </Route>
          </Route>

          <Route element={<RequireAdmin />}>
            <Route exact path="/admin" element={
              <>
                <PageTitle title="Canvique - Admin" />
                <AdminPage />
              </>
            } />
          </Route>

        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App;

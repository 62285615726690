
import React from 'react';

import Demo from '../demo/demo';
import Mosaic from '../mosaic/mosaic';
import Banner from '../banner/banner';
import Faq from '../faq/faq';
import Newsletter from '../newsletter/newsletter';
import PaymentLandPage from '../paymentLandPage/paymentLandPage';
import DeliveryLandPage from '../deliveryLandPage/deliveryLandPage';
import Gallery from '../gallery/gallery';

const Main = () => {

    return (
        <React.Fragment>
            <Banner />
            <Faq />
            <Gallery />
            <Demo />
            <Mosaic />
            <PaymentLandPage />
            <DeliveryLandPage />
            <Newsletter />
        </React.Fragment>
    );

};

export default Main;
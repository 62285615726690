

import React, {useEffect, useState} from "react";
import Button from "../common/button";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../loader/loader";
import { getAccount } from "../../api/user";
import { postSupport } from "../../api/contact";

const Support = (props) => {

    const { orderId } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [account, setAccount] = useState({});
    const [error, setError] = useState(false);

    const [order_id, setOrder] = useState(orderId || "");
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleSubmit = () => {
        postSupport(order_id, message)
        .then((res) => {
            navigate("/contact/success");
        })
        .catch((err) => {
            setError(true);
        })
    }


    useEffect(() => {
        getAccount()
        .then((res) => {
            setLoaded(true);
            setAccount(res.data);
        })
        .catch((err) => {
            setLoaded(true);
        })
    }, []);

    return(
        <>
            {
                loaded ? (
                    <>
                        <div className="py-6 flex flex-row bg-white gap-5 justify-center">
                            <div className="w-[800px] h-[800px] flex flex-col justify-center">
                                <h1 className="font-bold text-primary text-4xl">
                                    Need Help ?
                                </h1>
                                <h3 className="font-semibold text-xl text-primary">
                                    No worries {account.name}, we are here to help you!
                                </h3>
                                <div className="flex flex-col gap-2 p-4 text-sm">
                                    <div className="w-full min-w-[200px] max-h-[150px]">
                                        <input
                                            className={`w-full h-full p-2 rounded-sm bg-white border-[1px] ${error ? "border-delete-standard" : "border-primary"}`}
                                            placeholder="Number of your order. Should look like #12ab34cd... Or leave blank if you don't have one."
                                            onChange={(e) => setOrder(e.target.value)}
                                            value={order_id}
                                            />
                                    </div>
                                    {
                                        error &&
                                        <label className="text-sm font-light text-delete-standard">
                                            Couldn't find your order number, please double check!
                                        </label>
                                    }
                                    <div className="w-full max-h-[150px]">
                                        <textarea
                                            className="w-full h-full p-2 min-h-[75px] rounded-sm bg-white border-[1px] border-primary"
                                            placeholder="Write your message, your question here..."
                                            onChange={(e) => setMessage(e.target.value)}
                                            value={message}
                                            />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <Button onClick={()=>handleSubmit()}>
                                            Send!
                                        </Button>
                                        <label className="text-xs font-light">
                                            We will read your message soon and we reach you back as soon as possible!
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ):(
                    <div className="flex w-full h-[800px] justify-center">
                        <Loader />
                    </div>
                    )
            }
        </>
    )
}

export default Support;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FaBan } from "react-icons/fa";

import { getStyles } from '../../../../api/style';

import Loader from "../../../loader/loader";

import useOutsideClickEvent from '../../../../hooks/useOutsideClickEvent';

const ImageDropdown = (props) => {

  const [styles, setStyles] = useState([])
  const [loadingStyles, setLoadingStyles] = useState(false);

  const [open, setOpen] = useState(false);
  const imageDropdown = useRef(null);

  const fetchStyles = () => {
    setLoadingStyles(true);
    getStyles().then((res) => {
      setStyles(res.data.styles);
      setLoadingStyles(false);

    }).catch((err) => {

      setLoadingStyles(false);
      console.log(err);

    })
  }

  useEffect(()=>{
      fetchStyles();
  }, [])

  useOutsideClickEvent(imageDropdown, () => {
    setOpen(false);
  });

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  useEffect(() => {

    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <div
        className="group relative w-full cursor-pointer"
        onClick={() => setOpen(!open)}
        ref={imageDropdown}
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 bottom-0 w-5 h-5 my-auto text-gray-400 right-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
      <div className={`w-full px-3 py-2 text-sm bg-black
                      text-white rounded-lg shadow-sm outline-none
                      appearance-none hover:bg-primary group-hover:bg-primary
                      ${props.error ? "border-[1px] border-delete-standard" : ""}`}
      >
          {props.value != null ? (
                  <span className="flex flex-row gap-2 content-center">
                      {
                        styles.find(item => item.index === props.value).image === "" ? (
                          <FaBan className="w-6 h-6 bg-primary text-white"/>
                        ):(
                          <img alt=":(" src={`/styles/${styles.find(item => item.index === props.value).image}`} className="w-6 h-6"/>
                        )
                      }
                      <span className="flex items-center">
                          {styles.find(item => item.index === props.value).name}
                      </span>
                  </span>
              ) :
              (
                  props.title
              )}
      </div>
      {
          props.error && (
              <label
                  htmlFor="DropdownError"
                  className="block text-sm font-light text-delete-standard"
              >
                  {props.errorText}
              </label>
          )
      }
      {
              open && (

                  loadingStyles ? (
                    <Loader />
                  ) : (

                  <div className="absolute top-0 right-0 w-[100%] z-[1000] bg-black border-[1px] border-secondary">
                    <div className="w-full border-b-[1px] border-secondary flex flex-row items-center justify-between">
                      <div className="text-white font-light text-sm ml-2">
                        {props.title}
                      </div>
                      <button onClick={()=>setOpen(false)}
                              className="p-1 rounded-lg mr-[5px] text-gray-400 bg-black hover:bg-secondary hover:text-gray-600"
                      >
                          <IoClose className="w-[20px] h-[20px]" />
                      </button>
                    </div>
                    <div className="h-[400px] grid grid-cols-2 place-items-center py-3 gap-2 gap-y-3 overflow-x-hidden overflow-y-scroll"
                        onClick={() => setOpen(false)}
                    >
                        {styles.map((item) => {
                            return (
                                <>
                                <div key={item.index} className="w-full flex flex-col items-center"
                                      onClick={() => props.onChange(item.index)}
                                >
                                  <div className="bg-black w-[100%] h-[100%] p-2 rounded-md hover:border-[2px] hover:border-white">
                                    {
                                      item.image === "" ? (
                                          <FaBan className="w-full h-full p-[30px] text-white"/>
                                          ):(
                                            <img alt={`Failed to load ${item.name}`} src={`/styles/${item.image}`} className="w-full h-full rounded-md hover:border-[2px] hover:border-white"/>
                                            )
                                          }
                                    </div>
                                    <span className="flex items-center font-light text-white text-sm break-words text-center">
                                        {item.name}
                                    </span>
                                </div>
                                </>
                            )
                        })}
                    </div>
                  </div>

                  )
              )
          }
    </div>
  );
};

export default ImageDropdown;

import React from "react";


const Steps = (props) => {

    return (
        <div className="w-full">
            <div className="flex flex-row items-center justify-center gap-3">
                {
                    props.step.stepItems.map((o, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="flex flex-row items-center justify-center gap-1 hover:text-primary"
                                >
                                    <div className={`w-8 h-8 rounded-full border-[2px] ${props.step.currentStep > index - 1 ? "border-accent-standard hover:border-accent-active" : "border-secondary hover:border-primary"} flex items-center justify-center`}>
                                        <p className="text-black font-bold">{index + 1}</p>
                                    </div>
                                    <p className="text-sm font-bold">{o}</p>
                                </div>
                                {
                                    index !== props.step.stepItems.length - 1 && (
                                        <div className={`w-20 h-[3px] ${props.step.currentStep > index ? "bg-accent-standard" : "bg-secondary"}`}></div>
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default Steps;
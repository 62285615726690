
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";

import Register from '../../register/register';
import Login from '../../login/login';
import ProfileDropdown from "./profileDropdown";


const Profile = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    return(
        <>
            <div className="group relative cursor-pointer select-none">
                <FaUser className="w-[35px] h-[35px] text-white hover:text-secondary" 
                        onClick={() => setIsModalOpen(!isModalOpen)}
                />
                {
                    isModalOpen ?
                        <ProfileDropdown
                        setOpen={setIsModalOpen}
                        setIsLoginModalOpen={setIsLoginModalOpen}
                        setIsRegisterModalOpen={setIsRegisterModalOpen}
                        isOpen={isModalOpen}
                        /> : ''
                }    
            
            </div>

            {
                isRegisterModalOpen ?
                    <Register
                    setOpen={setIsRegisterModalOpen}
                    isOpen={isRegisterModalOpen}
                    setIsLoginModalOpen={setIsLoginModalOpen}
                    /> : ''
            }
            {
                isLoginModalOpen ?
                    <Login
                    setOpen={setIsLoginModalOpen}
                    isOpen={isLoginModalOpen}
                    setIsRegisterModalOpen={setIsRegisterModalOpen}
                    /> : ''
            }
        </>
    );
}

export default Profile;
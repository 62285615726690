
import React from "react";
import FaqItem from "./faqItem";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";

import { PiPaintBrushDuotone } from "react-icons/pi";
import { RiTruckFill } from "react-icons/ri";



const Faq = () => {

    const navigate = useNavigate();

    return (
        <>
            <section className="bg-black" id="faq">
                <div className="py-24 px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
                    <div className="flex flex-col text-left basis-1/2">
                        <div className="text-2xl font-bold text-white font-bricolage">
                            Frequently Asked Questions
                        </div>
                        <div className="text-white">
                            Have another question? <a href="/contact/message" className="text-accent-standard">Contact us here</a>.
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 basis-1/2">
                        <FaqItem title={
                            <p>
                                How to start creating on Canvique.ai?
                            </p>}>

                            <p>
                                <span className="font-semibold">1 - </span>
                                Simply click on <Button onClick={() => navigate("/create")} className="py-[8px] pl-[15px] pr-[15px]">Create</Button> in the navigation bar to begin crafting your unique artwork!
                            </p>
                            <p>
                                <span className="font-semibold">2 - </span>
                                Choose your creation method: Either express your ideas and create from scratch or import your own image to transform into a distinctive masterpiece.
                            </p>
                            <p>
                                <span className="font-semibold">3 - </span>
                                Transform favorites into canvases: After generating several artworks, select your preferred ones and turn them into canvas prints. Customize the size to your liking.
                            </p>
                            <p>
                                <span className="font-semibold">4 - </span>
                                Complete your order: Checkout and pay for your selected artworks. Receive an email confirmation shortly after confirming your order.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                Can I use my own images?
                            </p>}>

                            <p>
                                Of course you can! You can import your own images and turn them into unique artworks! Turn memories into art!
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                How long does it take to receive my order?
                            </p>}>

                            <p>
                                Usually, it takes up to 7 business days to receive your order worldwide. We'll keep you updated on your order's status.
                            </p>
                            <p>
                                <PiPaintBrushDuotone className="text-accent-standard w-[25px] h-[25px]"/> Giving life to your artwork takes 2-3 days, depending on the canvas size and concurrent artist activities.
                            </p>
                            <p>
                                <RiTruckFill className="text-accent-standard w-[25px] h-[25px]"/> Shipping your canvas to your address takes up to 4 business days, varying by location. Europe typically has faster delivery times.

                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                Styles and prompts on Canvique.ai?
                            </p>}>

                            <p>
                                We are constantly adding new styles to Canvique.ai. In terms of prompt you are totally free to write whatever you want. Let your imagination run wild!
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                What is the quality of the canvas?
                            </p>}>

                            <p>
                                We employ premium materials for our canvases and prints. Crafted from 100% cotton, our canvases boast superior quality, complemented by top-notch ink for the printing process. Our commitment ensures that the canvas quality consistently meets the highest standards.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                I am not happy with my order, what can I do?
                            </p>}>

                            <p>
                                No worries at all! If you are not satisfied with your order, you have the option to return it within 7 days of receiving it.
                            </p>
                            <p>
                                For more information, please read our <a href="/legal/return-policy" className="text-accent-standard">Return policy</a>.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                Preview before ordering?
                            </p>}>

                            <p>
                                Certainly! Feel free to preview your artwork before placing an order. Additionally, you have the option to preview your canvas and select its size before finalizing your order.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                Accepted payment methods?
                            </p>}>

                            <p>
                                We accept all major methods. Also your payments are secured by <a href="www.stripe.com">Stripe</a>, one of the most trusted payment provider in the world.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                What is the price of a canvas?
                            </p>}>

                            <p>
                                The cost of a canvas is determined by its size. Begin your creative process by <a href="/create" className="text-accent-standard">creating</a>, then convert it into a canvas to explore the pricing options and find the size that best suits your preferences.
                            </p>
                        </FaqItem>
                        <FaqItem title={
                            <p>
                                Is Canvique.ai artworks satisfaction guaranteed?
                            </p>}>

                            <p>
                                Should you find your artwork unsatisfactory, you have the option to return it within 7 days of receiving it. For additional details, please refer to our <a href="/legal/refund-policy" className="text-accent-standard">Refund Policy</a>.
                            </p>
                        </FaqItem>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
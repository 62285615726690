

import React from "react";
import Button from "../common/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postNewsletter } from "../../api/contact";


const NewsletterCTA = (props) => {

    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewsletter(email)
        .then((res) => {
            navigate("/contact/success");
        })
        .catch((err) => {
            console.log(err);
        })
    }

    return(
        <>
            <form onSubmit={handleSubmit}>
                <div className="w-full flex flex-row gap-2 p-4 text-sm">
                    <div>
                        <input 
                            className="min-w-[250px] h-full px-2 rounded-sm bg-white text-black font-bricolage" 
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                    </div>
                    <div>
                        <Button>
                            Subscribe
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default NewsletterCTA;
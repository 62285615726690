import React, { useState, useEffect, useContext } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import BasketContext from "../../../context/BasketProvider";
import {createCheckoutSession} from "../../../api/stripe";
import {STRIPE_PUBLIC_KEY} from "../../../api/constants";
import Loader from "../../loader/loader";


const Payment = (props) => {

    const {basketContent, basketAddress, basketId, loaded, basketDropdownOpen, setBasketDropdownOpen, fetchBasket} = useContext(BasketContext);


    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

    const [clientSecret, setClientSecret] = useState(null);

    useEffect(()=>{
        if(basketId){
            createCheckoutSession(basketId).then((response)=>{
                setClientSecret(response.data.client_secret);
            }).catch((error)=>{
                console.log(error);
            });
        }
    }, [basketId]);

    return(
        <>
            <h1 className="text-2xl font-bold">Payment</h1>
            <div className="w-full min-h-[600px]">
            {
                clientSecret ? (
                    <EmbeddedCheckoutProvider
                    options={{
                        clientSecret
                    }}
                    stripe={stripePromise}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                ) : (
                    <div className="flex w-full h-full justify-center items-center">
                        <Loader />
                    </div>
                )
            }
            </div>
        </>
    );
}

export default Payment;
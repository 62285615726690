
import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const getWalls = () => {

    const url = SERVER_ADDRESS+":"+SERVER_PORT+"/api/canvas/walls";

    return axios.get(url);
};

export const getPreview = (wallId, imageId, size) => {
    const token = localStorage.getItem("accessToken");
    let resize = ""
    if(size !== undefined)
        resize = "&size="+size;

    const url = SERVER_ADDRESS+":"+SERVER_PORT+"/api/canvas/preview?token="+token+"&wallId="+wallId+"&imageId="+imageId+resize;

    return url;
};

export const getDetails = (imageId) => {
    
        const token = localStorage.getItem("accessToken");
        const obj = {
            token: token,
            imageId: imageId};
    
        return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/data/image",
                            {
                                params: obj
                            }
                        );
    };

import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Basket from '../basket/basket';
import Logo from '../logo/logo';
import AuthContext from "../../context/AuthProvider";
import Button from '../common/button';
import Profile from './profile/profile';


const Header = (props) => {

  const {auth} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate('/create');
  }


  return (
    <header className="bg-primary">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <Logo />
          </div>

          <div className="md:flex md:items-center md:gap-12">

            <div className="flex items-center gap-4">
              <div className="sm:flex sm:gap-4">
                { auth && (
                    <div>
                      <Basket />
                    </div>
                  )
                }
              </div>
              <div className="hidden sm:block sm:text-sm md:text-base">
                  {
                    auth ?
                    <Button onClick={handleCreate}>
                      Start creating
                    </Button> : ''
                  }
              </div>
              <Profile />
            </div>
          </div>
        </div>
      </div>
    </header>
  );

}

export default Header;
import { GoogleLogin } from '@react-oauth/google';

const GoogleButton = (props) => {

    return(
        <>
            <div>
                <GoogleLogin
                    onSuccess={(e)=>props.handleSuccess(e)}
                    onError={(e)=>props.handleFailure(e)}
                    />
            </div>
        </>
    );
}


export default GoogleButton;

import React from 'react';
import Button from '../common/button';

const RefundPolicy = (props) => {

    return(
        <>
            <div className="fixed inset-0 z-100 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-2xl p-4 py-8 mx-auto bg-primary rounded-md shadow-lg">
                            <div className="mt-3">
                                <div className="flex flex-col gap-1 mt-2 text-center text-white">

                                    <h1 className="text-2xl font-bold">
                                        Refund Policy
                                    </h1>

                                    <div className="mt-4 text-justify">
                                        Thank you for choosing Canvique. 
                                        We strive to provide high-quality products and services to our customers. 
                                        However, we understand that there may be instances where you need to request a refund. 
                                        Please read our refund policy carefully.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Refund Eligibility
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        Refunds are only eligible for products purchased directly from Canvique.
                                        To be eligible for a refund, you must initiate the refund process within one week (7 days) of receiving the product.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        How to Request a Refund
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        Send an email to support@canvique.com with the subject line "Refund Request - [Your Order Number]."
                                        In the email, provide your order number, a detailed explanation of the reason for the refund, and any supporting documentation (if applicable).
                                        Our customer support team will review your request and respond within 2 business days.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Refund Process
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        If your refund request is approved, the refund will be processed using 
                                        the same payment method used for the original purchase.
                                        You will be asked to ship the product back to us at your own expense following 
                                        the instructions provided by our customer support team, and we will issue a refund once we receive the product.
                                        It usually takes 5-7 business days for the refund to be reflected in your account from the date of issue.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Non-Refundable Items:
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        Certain items are non-refundable as indicated during the checkout.
                                        However, any item that is damaged by the customer will not be eligible for a refund.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Contact Information
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        For any questions or concerns regarding our refund policy, 
                                        please contact us at support@canvique.com.
                                    </div>

                                    <h3 className="text-xl mt-2 font-semibold">
                                        Changes to Refund Policy
                                    </h3>

                                    <div className="mt-4 text-justify">
                                        Canvique reserves the right to modify or update this refund policy at any time. Changes will be effective immediately upon posting on our website.
                                        By making a purchase from Canvique, you acknowledge that you have read and agree to our refund policy.
                                        Thank you for choosing Canvique!
                                    </div>
                                    
                                    <div className="w-full justify-center">
                                        <Button onClick={()=>props.setOpen(false)} className="w-[200px]">
                                            Close
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );

};

export default RefundPolicy;
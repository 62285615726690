
import React from 'react';


const Dropdown = (props) => {
    
    return(
        <>
            <select onChange={props.onChange}
                    value={props.value}
                    className='w-full bg-white text-black h-[32px] p-1 mt-1 rounded-md shadow-sm'
            >
                <option value="" disabled defaultValue hidden>Please Choose...</option>
                {
                    props.options.map((option, key) => {
                        return(
                            <option key={key} value={option.value}>{option.label}</option>
                        )
                    })
                }
            </select>
        </>
    )
}

export default Dropdown;
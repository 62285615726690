
import React from "react";

import alien from  "../../assets/mosaic/alien.webp";
import comic from  "../../assets/mosaic/comic.webp";
import couplerain from  "../../assets/mosaic/couplerain.webp";
import einstein from  "../../assets/mosaic/einstein.webp";
import girls from  "../../assets/mosaic/girls.webp";
import map from  "../../assets/mosaic/map.webp";
import monkey from  "../../assets/mosaic/monkey.webp";
import moon from  "../../assets/mosaic/moon.webp";
import night from  "../../assets/mosaic/night.webp";
import noodles from  "../../assets/mosaic/noodles.webp";
import pizza from  "../../assets/mosaic/pizza.webp";
import planet from  "../../assets/mosaic/planet.webp";
import santaclaus from  "../../assets/mosaic/santaclaus.webp";
import surf from  "../../assets/mosaic/surf.webp";


const Image = (props) => {

        const {src} = props;

        return(
            <>
                <img src={src} alt="Mosaic image for AI art" />
            </>
        );
    }


const Mosaic = (props) => {

    return(
        <>
            <section className="grid grid-cols-3 md:grid-cols-5 gap-4 bg-black px-0 md:px-[100px] py-[25px]">
                <div className="grid gap-4">
                    <div>
                        <Image src={einstein} />
                    </div>
                    <div>
                        <Image src={noodles} />
                    </div>
                    <div>
                        <Image src={comic} />
                    </div>
                </div>
                <div className="md:grid hidden gap-4">
                    <div>
                        <Image src={moon} />
                    </div>
                    <div>
                        <Image src={santaclaus} />
                    </div>
                    <div>
                        <Image src={girls} />
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <Image src={couplerain} />
                    </div>
                    <div>
                        <div className="rounded-lg font-bold font-bricolage
                                        2xl:text-5xl xl:text-4xl lg:text-3xl md:text-xl text-lg
                                        text-right text-accent-standard"
                            >
                            free your imagination
                        </div>
                    </div>
                    <div>
                        <Image src={alien} />
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <Image src={map} />
                    </div>
                    <div>
                        <Image src={surf} />
                    </div>
                    <div>
                        <Image src={planet} />
                    </div>
                </div>
                <div className="md:grid hidden gap-4">
                    <div>
                        <Image src={monkey} />
                    </div>
                    <div>
                        <Image src={night} />
                    </div>
                    <div>
                        <Image src={pizza} />
                    </div>
                </div>
            </section>
        </>
    );
}


export default Mosaic;

import React from 'react';

const CheckedIcon = () => {
    return(
        <svg
        className="h-5 w-5 text-accent-standard"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        >
        <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
        />
        </svg>
    )
}


const ImageShape = (props) => {

    return(
        <>
            <div className="flex flex-col p-2">
                <div className="flex flex-row justify-evenly p-2">
                    {
                        props.shapes.map((elt, index) => {
                            return(
                                <>
                                    <div className="flex flex-col items-center justify-between gap-[4px]" key={index}>
                                        <div className={`flex items-center justify-center bg-black border-[1px] border-white cursor-pointer`}
                                            style={{
                                                width: elt.width+"px",
                                                height: elt.height+"px"
                                            }}
                                            onClick={() => {
                                                props.dispatch({type: "shape", payload: index});
                                                }
                                            }
                                            >
                                            {
                                                props.state.shape === index ? (
                                                    <CheckedIcon />
                                                ) : (
                                                    <div className="w-5 h-5 border-[1px] border-white rounded-full">
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="justify-center text-white">
                                            {
                                                elt.dimension
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default ImageShape;

import { createContext, useState, useEffect } from "react";
import { getBasket } from "../api/basket";

const BasketContext = createContext({});

export const BasketProvider = ({ children }) => {

    const [loaded, setIsLoaded] = useState(false);
    const [basketContent, setBasketContent] = useState([]);
    const [basketAddress, setBasketAddress] = useState(null);
    const [basketId, setBasketId] = useState(null);
    const [basketDropdownOpen, setBasketDropdownOpen] = useState(false);

    const fetchBasket = () => {
        getBasket()
        .then((res) => {
            setBasketAddress({
                full_name: res.data.full_name,
                address_line_1: res.data.address_line_1,
                address_line_2: res.data.address_line_2,
                country_id: res.data.country,
                city: res.data.city,
                postal_code: res.data.postal_code,
                state: res.data.state_province,
                contact_phone: res.data.contact_phone,
                state_province: res.data.state_province,
            });
            setBasketContent(res.data.items);
            setBasketId(res.data.basket_id);
            setIsLoaded(true);
        })
        .catch((err) => {
            setBasketContent([]);;
            setBasketId(null);
            setIsLoaded(true);
        });
    }

    useEffect(() => {
        fetchBasket();
    },[]);

    return (
        <BasketContext.Provider value={{ basketContent, basketAddress, basketId, loaded, basketDropdownOpen, setBasketDropdownOpen, fetchBasket}}>
            {children}
        </BasketContext.Provider>
    )

}

export default BasketContext;

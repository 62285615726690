
import React from 'react';

import truck from "../../assets/truck.webp";


const PaymentLandPage = () => {
    return (
        <section className="relative w-full h-full flex bg-white justify-center items-center">
            <img className="w-full h-[30vh] object-cover object-center" 
                     src={truck} 
                     alt="Canvique AI delivery truck" />
            <div className="absolute inset-0 bg-gradient-to-r from-black">
            </div>

            <div className="absolute text-left bottom-[15%] sm:w-[50%] w-[80%] m-[20px] sm:m-0 sm:left-[15%] left-[5%]">
                    <h1 className="text-4xl flex flex-row text-accent-standard font-black font-bricolage items-center">
                        <p>
                            Get delivered with FedEx
                        </p>
                    </h1>
                    <p className="text-white">
                        After 2-3 days of production, you will promptly receive your tracking number and
                        your order will be shipped to your address.
                    </p>
                </div>

        </section>
    );
}

export default PaymentLandPage;


import axios from "axios";
import { SERVER_ADDRESS, SERVER_PORT } from "./constants";

export const getCountries = () => {
    return axios.get(SERVER_ADDRESS+":"+SERVER_PORT+"/api/countries",
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        crossDomain: true
                    });
}

import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";

export const get_ai_sizes_and_price = (width, height) => {
    
    const url = SERVER_ADDRESS+":"+SERVER_PORT+"/api/canvas_size_and_price?ai_width="+width+"&ai_height="+height;
    return axios.get(url);
}

export const get_shipping_price = (basket_id, country_id) => {
    const token = localStorage.getItem("accessToken");
    const url = SERVER_ADDRESS+":"+SERVER_PORT+"/api/user/shipping_price?basket_id="+basket_id+"&country_id="+country_id+"&token="+token;
    return axios.get(url);
}

export const get_ai_sizes = () => {
    const url = SERVER_ADDRESS+":"+SERVER_PORT+"/api/ai_sizes";
    return axios.get(url);
}
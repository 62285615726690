import axios from "axios";
import {SERVER_ADDRESS, SERVER_PORT} from "./constants";


export const postImage2Image = (file,
                             positivePrompt,
                             negativePrompt,
                             style,
                             updateStrength) => {

    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('file', file);
    formData.append('positive_prompt', positivePrompt);
    formData.append('negative_prompt', negativePrompt);
    formData.append('style', style);
    formData.append('update_strength', updateStrength);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/generate/image2image",
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' },
                            withCredentials: true,
                            crossDomain: true
                        });
};

export const postText2Image = (
    width,
    height,
    positivePrompt,
    negativePrompt,
    style
    ) => {

    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append('token', token);
    formData.append('positive_prompt', positivePrompt);
    formData.append('negative_prompt', negativePrompt);
    formData.append('style', style);
    formData.append('width', width);
    formData.append('height', height);

    return axios.post(SERVER_ADDRESS+":"+SERVER_PORT+"/api/generate/text2image",
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
            crossDomain: true
        });
};
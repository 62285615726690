


const ContactSuccess = () => {
    return (
        <div className="flex w-full justify-center h-[800px] items-center">
            <div className="text-center">
                <h2 className="text-4xl text-accent-standard font-bold">Received!</h2>
                <div>
                    <p className="text-primary text-xl font-semibold">Thank you!</p>
                    <p className="text-primary text-md font-semibold">We will read it soon and we will reach you back as soon as possible!</p>
                </div>
            </div>
        </div>
    );
}

export default ContactSuccess;

import React from 'react';
import Item from './item';

const Prompt = (props) => {

    return(
        <Item {...props}>
            <div className="flex flex-col gap-0">
                <textarea
                className={`placeholder:text-muted-white resize-none
                overflow-y-clip rounded text-black shadow-sm
                           focus:outline-none
                           text-white h-10
                           bg-transparent w-full px-0 py-0 ${props.error ? "border-[1px] border-delete-standard" : ""}`}
                rows="2"
                placeholder={props.placeholder}
                {...props}
                ></textarea>
                {
                    props.error && (
                        <label
                            htmlFor="PromptError"
                            className="block text-sm font-light text-delete-standard"
                        >
                            {props.errorText}
                        </label>
                    )
                }
            </div>
        </Item>
    )
}

export default Prompt;
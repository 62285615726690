
import React from "react";


export const Input = React.forwardRef((props, ref)  => {

    const { className, ...rest } = props;

    const classnames = "mt-1 p-1 w-full rounded-md text-black shadow-sm focus:outline-0 " + className;

    return(
        <React.Fragment>
            <input
            type={rest.type}
            id={rest.id}
            name={rest.name}
            className={classnames}
            {...rest}
            ref={ref}
            />
        </React.Fragment>
    );

});

export const Checkbox = (props) => {
    return(
        <React.Fragment>
            <input
                type="checkbox"
                id={props.id}
                name={props.name}
                className="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm dark:focus:ring-offset-gray-900"
                {...props}
            />
        </React.Fragment>
    )
};

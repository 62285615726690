import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import MoonLoader from "react-spinners/MoonLoader";

const RequireAdmin = ({ setIsLoginModalOpen }) => {
    const { auth, isAdminLoading, isAdmin } = useAuth()
    const location = useLocation();

    useEffect(() => {
        if (!isAdminLoading){
            if (!auth) {
              setIsLoginModalOpen(true);
            }
        }
      }, [auth, isAdminLoading]);

    return (
      isAdminLoading ?
          <div className="bg-white h-screen from-gray-950 to-gray-800 flex flex-col items-center justify-center p-25">
              <MoonLoader className="color-gray-100"/>
          </div>
        :(
            isAdmin ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
        )
    );
}

export default RequireAdmin;
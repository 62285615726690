
import React, { useState, useEffect, useRef } from 'react';

import img1 from '../../assets/gallery/IMG1.jpeg';
import img2 from '../../assets/gallery/IMG2.jpeg';
import img3 from '../../assets/gallery/IMG3.jpeg';
import img4 from '../../assets/gallery/IMG4.jpeg';
import img5 from '../../assets/gallery/IMG5.jpeg';
import img6 from '../../assets/gallery/IMG6.jpeg';
import img7 from '../../assets/gallery/IMG7.jpeg';
import img8 from '../../assets/gallery/IMG8.jpeg';
import video from '../../assets/IMG_5472.MOV';


const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const Image = (props) => {

    return(
        <>
            <div className={`relative w-full h-full cursor-pointer ${props.visible ? "block" : "hidden"}`}>
                <img src={props.img} className="w-full h-[100vh] object-cover object-center" alt="Gallery image 1" />
                <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                <div className="absolute text-left bottom-[75px] sm:w-[50%] w-[80%] m-[20px] sm:m-0 sm:left-[30%] left-[10%]">
                    <p className="text-accent-standard text-5xl font-black font-bricolage">
                        {props.title}
                    </p>
                    <p className="text-lg font-bricolage text-white font-medium">
                        {props.description}
                    </p>
                </div>
            </div>
        </>
    )

}

const Gallery = (props) => {

    const data = [
        {
            img: img1,
            title: "High quality canvas",
            description: "100% cotton canvas mounted on wooden frames. Printed with the latest technology and UV resistant inks."
        },
        {
            img: img2,
            title: "Gallery-Worthy Artwork",
            description: "Elevate your walls with our museum-quality canvas prints. Designed to last a lifetime, our canvases are printed using archival inks and wrapped around sturdy wooden frames for a professional finish."
        },
        {
            img: img3,
            title: "Customized Creations",
            description: "Unleash your creativity with our customizable canvas prints. Whether it's a cherished memory, a beloved pet, or a favorite landscape, our canvases turn your vision into a work of art."
        },
        {
            img: img4,
            title: "Timeless Elegance",
            description: "Add a touch of sophistication to your home decor with our timeless canvas prints. With their classic appeal and timeless charm, our canvases never go out of style."
        },
        {
            img: img5,
            title: "Artisan Craftsmanship",
            description: "Experience the craftsmanship of our artisanal canvas prints. Each canvas is meticulously crafted by skilled hands to ensure the highest quality and attention to detail."
        },
        {
            img: img6,
            title: "Modern Minimalism",
            description: "Embrace the beauty of simplicity with our modern canvas prints. Featuring clean lines and minimalist designs, our canvases add a touch of contemporary flair to any room."
        },
        {
            img: img7,
            title: "Vibrant Expression",
            description: "Make a statement with our vibrant canvas prints. From bold colors to striking patterns, our canvases let you express your personality and style with flair."
        },
    ];

    const [currentImg1, setCurrentImg1] = useState(0);
    const [currentImg2, setCurrentImg2] = useState(1);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const heightThreshold = 768;
    const videoRef = useRef(null);

      useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            let new_index = windowDimensions.width > heightThreshold ? currentImg1+2 : currentImg1+1
            setCurrentImg1(new_index % data.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [currentImg1]);

    useEffect(() => {
        const interval = setInterval(() => {
            let new_index = windowDimensions.width > heightThreshold ? currentImg2+2 : currentImg2+1
            setCurrentImg2(new_index % data.length);
        }, 5000);
        return () => clearInterval(interval);
    }   , [currentImg2]);

    const setPlayBack = () => {
        videoRef.current.playbackRate = 0.5;
    };


    return(
        <>
            <div className="w-full h-[80vh] bg-black flex flex-row">
                {/* {
                    data.map((img, index) => {
                        return(
                            <>
                                <Image img={img.img}
                                    title={img.title}
                                    description={img.description}
                                    visible={index === currentImg1 || (index === currentImg2 && windowDimensions.width > heightThreshold)}
                                />
                            </>
                        )
                    })
                } */}
                 <div className="relative w-full h-full cursor-pointer ">
                    <video ref={videoRef} onCanPlay={() => setPlayBack()} aria-hidden="true" className="relative h-full w-full object-cover transition-opacity duration-300 pointer visible opacity-100" autoPlay loop playsInline muted>
                        <source src={video} />
                            Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-gradient-to-t from-black"></div>
                    <div className="absolute text-left bottom-[75px] sm:w-[50%] w-[80%] m-[20px] sm:m-0 sm:left-[30%] left-[10%]">
                    <p className="text-accent-standard text-5xl font-black font-bricolage">
                        High quality canvas
                    </p>
                    <p className="text-lg font-bricolage text-white font-medium">
                        100% cotton canvas mounted on wooden frames. Printed with the latest technology and UV resistant inks.
                    </p>
                </div>
                 </div>
            </div>
        </>
    );
}

export default Gallery;
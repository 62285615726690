
import React, { useState, useEffect, useRef } from 'react';
import Alert from '../common/alert';
import Button from '../common/button';

import { Input } from "../common/input";
import { changePassword } from '../../api/user';


const ChangePassword = (props) => {
    
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertPositive, setAlertPositive] = useState(false);

    const alertRef = useRef();
    
    const PWD_REGEX = /^.{8,28}$/;

    const [pwd, setPwd] = useState('');

    const [newPwd, setNewPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [confirmPwd, setConfirmPwd] = useState('');
    const [validConfirmPwd, setValidConfirmPwd] = useState(false);
    const [pwdConfirmFocus, setConfirmPwdFocus] = useState(false);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(newPwd) && newPwd.length >= 8 && newPwd.length <= 24);
    }, [newPwd])

    useEffect(() => {
        setValidConfirmPwd(newPwd == confirmPwd);
    }, [confirmPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        changePassword(pwd, newPwd).then((res)=>{
            setAlertPositive(true);
            setAlertTitle('Success');
            setAlertMessage('Password changed successfully');
            setAlertVisible(true);
            setPwd('');
            setNewPwd('');
            setConfirmPwd('');
        }).catch((err)=>{
            console.log(err);
            setAlertPositive(false);
            setAlertTitle('Error');
            if (!err?.response) {
                setAlertMessage('No Server Response');
            } else {
                setAlertMessage(err.response?.data?.detail);
            }
            setAlertVisible(true);
        });
    }

    return(
        <>
            <div className="fixed inset-0 z-100 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 py-8 mx-auto bg-primary rounded-md shadow-lg">
                            <div className="mt-3">
                                <div className="mt-2 text-center">
                                    <div className="flex flex-cols">
                                        <h2
                                        className="text-2xl font-bold text-white sm:text-3xl md:text-4xl font-bricolage"
                                        >
                                            Change Password
                                        </h2>
                                    </div>
                                    <Alert
                                        ref={alertRef}
                                        positive={alertPositive}
                                        title={alertTitle}
                                        text={alertMessage}
                                        visible={alertVisible}
                                        setVisible={setAlertVisible}
                                    />
                                    <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-3 gap-6 text-left">
                                        <div className="col-span-3">
                                            <label
                                                htmlFor="Old Password"
                                                className="block text-sm font-medium text-white"
                                                >
                                            Old Password
                                            </label>
                                            <Input
                                                type="password"
                                                id="Password"
                                                name="password"
                                                onChange={(e) => {setPwd(e.currentTarget.value)}}
                                                required
                                                value={pwd}
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                            />
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                            htmlFor="Password"
                                            className="block text-sm font-medium text-white"
                                            >
                                            New Password
                                            </label>

                                            <Input
                                                type="password"
                                                id="newPassword"
                                                name="new password"
                                                className={!validPwd && newPwd ? "border-2 border-rose-500" : ""}
                                                onChange={(e) => {setNewPwd(e.currentTarget.value)}}
                                                aria-invalid={validPwd ? "false" : "true"}
                                                required
                                                value={newPwd}
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                            />
                                            {!validPwd && pwdFocus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    8 to 28 characters.<br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3">
                                            <label
                                            htmlFor="Password"
                                            className="block text-sm font-medium text-white"
                                            >
                                            New Password
                                            </label>

                                            <Input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirm password"
                                                className={!validConfirmPwd && confirmPwd ? "border-2 border-rose-500" : ""}
                                                onChange={(e) => {setConfirmPwd(e.currentTarget.value)}}
                                                aria-invalid={confirmPwd ? "false" : "true"}
                                                required
                                                value={confirmPwd}
                                                onFocus={() => setConfirmPwdFocus(true)}
                                                onBlur={() => setConfirmPwdFocus(false)}
                                            />
                                            {!validConfirmPwd && pwdConfirmFocus &&
                                                <span className="mt-2 text-sm text-rose-500">
                                                    Passwords mismatch<br />
                                                </span>
                                            }
                                        </div>

                                        <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                                            <Button
                                                disabled={(newPwd.length>0 &&
                                                           confirmPwd.length>0 &&
                                                           validPwd &&
                                                           validConfirmPwd) ? false : true}
                                            >
                                                Change password
                                            </Button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );

};

export default ChangePassword;

import React, { useState, useReducer, useEffect, useRef } from 'react';
import Steps from '../steps';
import SideBar from './sidebar/sidebar';
import History from './history';

import ImageModal from './snapshot/imageModal';
import GenerateLoader from './generateLoad';

import { getHistory } from "../../../api/image";
import {get_ai_sizes} from '../../../api/sizes';

const reducer = (state, action) => {

    switch (action.type) {
        case "positivePrompt":
            return {...state, "positivePrompt" : action.payload};
        case "negativePrompt":
            return {...state, "negativePrompt" : action.payload};
        case "style":
            return {...state, "style" : action.payload};
        case "updateStrength":
            return {...state, "updateStrength" : action.payload};
        case "image":
            return {...state, "image" : action.payload};
        case "file":
            return {...state, "file" : action.payload};
        case "shape":
            return {...state, "shape" : action.payload};
        case "shape_data":
            return {...state, "shape_data" : action.payload};
        default:
            return state;
    }
};

const ErrorReducer = (state, action) => {
    switch (action.type) {
        case "positivePrompt":
            return {...state, "positivePrompt" : action.payload};
        case "style":
            return {...state, "style" : action.payload};
        case "updateStrength":
            return {...state, "updateStrength" : action.payload};
        default:
            return state;
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }


const Create = (props) => {

    const [step, setStep] = useState({
        stepItems: ["Create", "Make it a canvas", "Checkout"],
        currentStep: 0
    })

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const containerRef = useRef(null);
    const mainContainerRef = useRef(null);

    const [shapes, setShapes] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snapshots, setSnapshots] = useState([]);
    const [history, setHistory] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [modalURL, setModalURL] = useState(null);
    const [modalID, setModalID] = useState(null);

    const [loadingHistory, setLoadingHistory] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchHistory = () => {
        setLoadingHistory(true);
        if(!hasMore) return;
        getHistory(page)
            .then(res => {
                if(res.data.history.length === 0){
                    setHasMore(false);
                }
                setHistory([...history, ...res.data.history]);
                setPage(page => page + 1);
                setLoadingHistory(false);
            })
            .catch(err => {
                console.log(err);
                setLoadingHistory(false);
            });
    }

    const [state, dispatch] = useReducer(reducer, {
        "style": null,
        "positivePrompt" : "",
        "negativePrompt" : "",
        "updateStrength": null,
        "image": null,
        "file": null,
        "shape": 0
    });

    const [errorState, errorDispatch] = useReducer(ErrorReducer, {
        "style": true,
        "positivePrompt" : true,
        "updateStrength": true,
    });

    const updateSnapshot = (e) => {
        setSnapshots(e);
        setHistory([...e, ...history]);
    }

    useEffect(()=>{
        fetchHistory();
        get_ai_sizes().then((res) => {
            setShapes(res.data.sizes.map((elt) => {

                let max = Math.max(elt.width, elt.height);
                let w = Math.round(elt.width/max*75);
                let h = Math.round(elt.height/max*75);

                return({
                    "dimension": elt.width+"x"+elt.height,
                    "width": w,
                    "height": h,
                    "originalWidth": elt.width,
                    "originalHeight": elt.height
                });
            }));
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(()=>{
        if(openModal){
            containerRef.current.style.overflowY = "hidden";
        }else{
            containerRef.current.style.overflowY = "auto";
        }
    }, [openModal]);

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const handleScroll = (e) => {
        if (Math.round(containerRef.current.clientHeight + containerRef.current.scrollTop) + 2 < containerRef.current.scrollHeight || loadingHistory || !hasMore) {
          return;
        }
        fetchHistory();
    };

    return(
        <React.Fragment>
            <div className="bg-white w-full flex flex-row"
                style={{height: (windowDimensions.height - 64)+"px"}}
                ref={mainContainerRef}
            >
                <SideBar
                    state={state}
                    errorState={errorState}
                    errorDispatch={errorDispatch}
                    dispatch={dispatch}
                    setSnapshots={updateSnapshot}
                    setLoaded={setLoaded}
                    setLoading={setLoading}
                    shapes={shapes}
                    />
                <div className="w-full h-full">
                    <div className="w-full h-full p-4 flex flex-col items-center overflow-x-hidden"
                         ref={containerRef}
                         onScroll={handleScroll}
                    >
                        <Steps
                            step={step}
                            setStep={setStep}
                        />

                        <div className="w-full flex flex-wrap justify-evenly flex-row justify-center m-4">
                            <ImageModal
                                setOpen={setOpenModal}
                                open={openModal}
                                id={modalID}
                                url={modalURL}
                                SideBarDispatcher={dispatch}
                                />
                            {
                                loading && (
                                    <GenerateLoader />
                                )
                            }
                        </div>

                        <div className="w-full">
                            <div className="w-full border-b-[1px] text-center">
                                <span className="text-sm py-2">
                                    History
                                </span>
                            </div>
                            <div className="w-full mt-5">
                                <History history={history}
                                         loading={loadingHistory}
                                         setHistory={setHistory}
                                         setModalID={setModalID}
                                         setModalURL={setModalURL}
                                         setOpenModal={setOpenModal}
                                         containerRef={containerRef}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Create;


import React, { useState, useRef, useEffect } from 'react';
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import ReactGA  from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';

import Dropdown from './dropdown';
import ImageDropdown from './imageDropdown';
import Prompt from './prompt';
import Button from '../../../common/button';
import ImageUpload from './imageuploader';
import Item from './item';
import ImageShape from './imageShape';

import useOutsideClickEvent from '../../../../hooks/useOutsideClickEvent';

import { postText2Image, postImage2Image } from '../../../../api/generate';


const SideBar = (props) => {

    const [deployed, setDeployed] = useState(false);
    const sidebarRef = useRef(null);

    const [positivePromptError, setPositivePromptError] = useState(false);
    const [styleError,          setStyleError]          = useState(false);
    const [updateStrengthError, setUpdateStrengthError] = useState(false);

    useOutsideClickEvent(sidebarRef, () => {
        setDeployed(false);
    });

    const inspirations = [
        "Abstract",
        "Animals",
        "Anime",
        "Art",
        "Cars",
    ]

    const updateStrength = [
        "Keep it very original",
        "Modify it a little",
        "Modify it a lot",
        "Completely change it"
    ]


    const uploadImage = (e) => {
        props.dispatch({type: "image", payload: e});
    }

    const handleGenerate = () => {

        ReactGA.event({
            category: "Button",
            action: "Generate",
            label: "Generate button clicked"
        });

        ReactPixel.trackCustom('Generate', {
            action: "Generate",
            label: "Generate button clicked"
        });

        TiktokPixel.track('Generate');

        if (props.state.file == null && (props.errorState.positivePrompt || props.errorState.style)){
            setPositivePromptError(props.errorState.positivePrompt);
            setStyleError(props.errorState.style);
            return;
        }else if(props.state.file != null && (props.errorState.positivePrompt || props.errorState.style || props.errorState.updateStrength)){
            setPositivePromptError(props.errorState.positivePrompt);
            setStyleError(props.errorState.style);
            setUpdateStrengthError(props.errorState.updateStrength);
            return;
        }else{
            setDeployed(false);
            props.setLoading(true);
            props.setLoaded(false);
            if(props.state.image){
                postImage2Image(props.state.file,
                    props.state.positivePrompt,
                    props.state.negativePrompt,
                    props.state.style,
                    props.state.updateStrength).then((res) => {
                        props.setSnapshots(res.data.images);
                        props.setLoading(false);
                        props.setLoaded(true);
                    }
                    ).catch((err) => {
                        props.setLoading(false);
                            props.setLoaded(false);
                            console.log(err);
                        }
                        );
            }else{
                postText2Image(
                    props.shapes[props.state.shape].originalWidth,
                    props.shapes[props.state.shape].originalHeight,
                    props.state.positivePrompt,
                    props.state.negativePrompt,
                    props.state.style
                    ).then((res) => {
                        console.log(res);
                        props.setSnapshots(res.data.images);
                        props.setLoading(false);
                        props.setLoaded(true);
                    }).catch((err) => {
                        props.setLoading(false);
                            props.setLoaded(false);
                            console.log(err);
                        }
                    );
            }
        }
    }

    const handlePositivePrompt = (e) => {
        props.dispatch({type: "positivePrompt", payload: e.target.value});
    }

    const handleNegativePrompt = (e) => {
        props.dispatch({type: "negativePrompt", payload: e.target.value});
    }

    const handleStyle = (e) => {
        props.dispatch({type: "style", payload: e});

    }

    const handleStrength = (e) => {
        props.dispatch({type: "updateStrength", payload: e.target.value});
    }

    useEffect(()=>{
        props.errorDispatch({type: "style", payload: props.state.style === null});
        setStyleError(false);
    }, [props.state.style])


    useEffect(() => {
        props.errorDispatch({type: "updateStrength", payload: props.state.updateStrength === null});
        setUpdateStrengthError(false);
    }, [props.state.updateStrength]);

    useEffect(() => {
        props.errorDispatch({type: "positivePrompt", payload: props.state.positivePrompt === ""});
        setPositivePromptError(false);
    }, [props.state.positivePrompt]);

    return (
        <>
            <div className={`fixed md:static bg-primary w-full
                             border-[1px] border-secondary
                             md:min-w-[350px] xl:min-w-[576px] md:w-[250px] xl:w-[576px]
                             max-h-screen min-h-0
                             flex flex-col items-center
                             rounded-t-xl md:rounded-none z-[1000]
                             bottom-[-1px]`}
                 ref={sidebarRef}
                 onClick={() => setDeployed(!deployed)}
            >
                <div className="p-2 w-full">
                    <div className="md:hidden cursor-pointer h-[30px] w-full md:cursor-default flex justify-center items-center"
                        onClick={(e) => setDeployed(!deployed)}>
                        {
                            deployed ? <SlArrowDown className="text-white" /> : <SlArrowUp className="text-white" />
                        }
                    </div>
                    <Button className="w-[100%] bg-accent-standard hover:enabled:bg-accent-active" onClick={(e) => {e.stopPropagation(); handleGenerate()}}>
                        Generate
                    </Button>
                </div>
                <div className={`flex md:max-h-full max-h-[60vh] min-h-0 w-full shrink grow-0 flex-col overflow-y-auto gap-2 pb-[25px] px-2 sm:p-4 ${deployed ? "md:flex" : "hidden md:flex"}`}
                     onClick={(e) => e.stopPropagation()}
                >
                    <Item
                        title="Style"
                        deployed={true}
                        deployedicon={<SlArrowDown className="text-white" />}
                        undeployedicon={<SlArrowUp className="text-white" />}
                    >
                        <ImageDropdown
                            onChange={(e)=>handleStyle(e)}
                            value={props.state.style}
                            title="Choose an optional style"
                            error={styleError}
                            errorText="Please choose a style"
                        />
                    </Item>

                    <Prompt placeholder="What would you like to see..."
                            onChange={(e) => handlePositivePrompt(e)}
                            value={props.state.positivePrompt}
                            deployed={true}
                            deployedicon={<SlArrowDown className="text-accent-standard" />}
                            undeployedicon={<SlArrowUp className="text-accent-standard" />}
                            title="Prompt"
                            error={positivePromptError}
                            errorText="Please enter a prompt"
                    />
                    <Prompt placeholder="Optional : What would you not like to see..."
                            onChange={(e) => handleNegativePrompt(e)}
                            value={props.state.negativePrompt}
                            deployedicon={<SlArrowDown className="text-delete-standard" />}
                            undeployedicon={<SlArrowUp className="text-delete-standard" />}
                            title="Negative Prompt"
                    />

                    <Item
                        title="Image"
                        deployed={true}
                        deployedicon={<SlArrowDown className="text-white" />}
                        undeployedicon={<SlArrowUp className="text-white" />}
                    >
                        <ImageUpload image={props.state.image}
                                    setImage={uploadImage}
                                    setFile={(e) => {props.dispatch({type: "file", payload: e})}}
                                    setUpdateStrengthError={setUpdateStrengthError}
                                    errorDispatch={props.errorDispatch}
                        />
                        <Dropdown
                            onChange={(e) => handleStrength(e)}
                            value={props.state.updateStrength}
                            list={updateStrength}
                            title="How much would you like to change the image?"
                            error={updateStrengthError}
                            errorText="Please choose an option"
                        />
                    </Item>

                    {
                        !props.state.image && (
                            <Item
                                title="Shape"
                                deployed={true}
                                deployedicon={<SlArrowDown className="text-white" />}
                                undeployedicon={<SlArrowUp className="text-white" />}
                            >
                                <ImageShape
                                    shapes={props.shapes}
                                    state={props.state}
                                    dispatch={props.dispatch}
                                />
                            </Item>
                        )
                    }

                    <Dropdown
                        list={inspirations}
                        title="Need some inspiration ?"
                    />

                </div>
            </div>
        </>
    );
}

export default SideBar;
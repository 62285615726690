
import React from "react";
import {useState, useRef, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";

import GoogleButton from "../googleButton/googleButton";
import Alert from "../common/alert";
import Button from "../common/button";
import AuthContext from "../../context/AuthProvider";

import { googleLogin } from "../../api/google";
import {postLogin} from "../../api/user";
import {Input} from "../common/input";


const Login = (props) => {

    const {setAuth} = useContext(AuthContext);

    const alertRef = useRef();
    const navigate = useNavigate();

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertPositive, setAlertPositive] = useState(false);

    const [email, setEmail] = useState('');

    const [pwd, setPwd] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try{
            const response = await postLogin(email, pwd);
            const accessToken = response?.data?.access_token;
            setAuth({accessToken});
            localStorage.setItem('accessToken', accessToken);
            navigate("/create");
            props.setOpen(false);
        }catch(err){
            setAlertPositive(false);
            setAlertTitle('Error');
            if (!err?.response) {
                setAlertMessage('No Server Response');
            } else {
                setAlertMessage(err.response?.data?.detail);
            }
            setAlertVisible(true);
        }
    }

    const handleRegisterClick = () => {
        props.setIsRegisterModalOpen(true);
        props.setOpen(false);
    }

    const handlePasswordForgottenClick = () => {
        navigate("/password-forgotten");
        props.setOpen(false);
    }

    const googleSuccess = (e) => {
        googleLogin(e.credential).then((response)=>{
            const accessToken = response?.data?.access_token;
            setAuth({accessToken});
            localStorage.setItem('accessToken', accessToken);
            props.setOpen(false);
        }).catch((err)=>{
            setAlertPositive(false);
            setAlertTitle('Error');
            if (!err?.response) {
                setAlertMessage('No Server Response');
            } else {
                setAlertMessage(err.response?.data?.detail);
            }
            setAlertVisible(true);
        })
    }

    const googleFailure = (e) => {
        console.log(e);
    }

    return(

    <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-black opacity-80" onClick={() => props.setOpen(false)}></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 py-8 mx-auto bg-primary rounded-md shadow-lg">
                    <div className="mt-3">
                        <div className="mt-2 text-center">
                            <div className="flex flex-cols">
                                <h2
                                className="text-2xl font-bold text-white sm:text-3xl md:text-4xl font-bricolage"
                                >
                                    Welcome to Canvique
                                </h2>
                            </div>
                            <Alert
                                ref={alertRef}
                                positive={alertPositive}
                                title={alertTitle}
                                text={alertMessage}
                                visible={alertVisible}
                                setVisible={setAlertVisible}
                            />

                            <form onSubmit={handleSubmit} className="mt-8 grid grid-cols-3 gap-6 text-left">

                                <div className="col-span-3">
                                    <label
                                    htmlFor="Email"
                                    className="block text-sm font-medium text-gray-200"
                                    >
                                    Email
                                    </label>

                                    <Input
                                        type="email"
                                        id="Email"
                                        name="email"
                                        onChange={(e) => {setEmail(e.currentTarget.value)}}
                                        value={email}
                                    />
                                </div>

                                <div className="col-span-3">
                                    <label
                                    htmlFor="Password"
                                    className="block text-sm font-medium text-gray-200"
                                    >
                                    Password
                                    </label>

                                    <Input
                                        type="password"
                                        id="Password"
                                        name="password"
                                        onChange={(e) => {setPwd(e.currentTarget.value)}}
                                        required
                                        value={pwd}
                                    />
                                </div>

                                <div className="col-span-3 text-center">
                                    <span className="w-full text-center text-accent-standard hover:text-accent-active cursor-pointer underline"
                                          onClick={()=>{handlePasswordForgottenClick()}}
                                    >
                                        Password forgotten ? Click here 
                                    </span>
                                </div>

                                <div className="flex col-span-3 justify-center">
                                    <GoogleButton 
                                        handleSuccess={googleSuccess}
                                        handleFailure={googleFailure}
                                    />
                                </div>

                                <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                                    <Button
                                        disabled={!email || !pwd ? true : false}
                                    >
                                        Login
                                    </Button>

                                    <p className="mt-4 text-sm text-white sm:mt-0">
                                        Don't have an account ?&nbsp;
                                        <a className="underline text-accent-standard cursor-pointer" onClick={()=>handleRegisterClick()}>
                                            Register
                                        </a>.
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    );
}

export default Login;